// InternalDischargePDF.js
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ShopService from './ShopService';

export const generateInternalDischargePDF = async (documentDetails, customerData, inputData) => {
  const doc = new jsPDF();

  try {
    // Get shop data
    const shopData = await ShopService.getDefaultShop();
    if (!shopData) {
      throw new Error('Dati del negozio non trovati');
    }

    // Header - Mittente
    doc.setFontSize(14);
    doc.text(shopData.name || '', 10, 10);
    doc.setFontSize(12);
    doc.text(shopData.address || '', 10, 15);
    doc.text(`${shopData.cap || ''} ${shopData.city || ''} ${shopData.province ? `(${shopData.province})` : ''}`, 10, 20);
    doc.text(`P.IVA/CF: ${shopData.vatInfo || ''}`, 10, 25);

    // Customer Info
    doc.setFontSize(12);
    doc.text("Intestatario", 10, 50);
    doc.text(`${customerData.companyName || 'undefined'}`, 10, 55);
    doc.text(`${customerData.address || 'undefined'}`, 10, 60);
    doc.text(`${customerData.zip_code || 'undefined'} ${customerData.city || 'undefined'} ${customerData.country || 'undefined'}`, 10, 65);
    doc.text(`${customerData.phone || 'undefined'}`, 10, 70);
    doc.text(`${customerData.email || 'undefined'}`, 10, 75);

    // Document Details
    doc.text("Tipo Documento: Scarico Interno", 140, 50);
    doc.text(`Nr: ${documentDetails.document_number}`, 140, 55);
    doc.text(`Data: ${documentDetails.creation_date}`, 140, 60);

    // Table - Document Lines
    const tableColumnHeaders = ["Quantità", "Descrizione", "Prezzo"];
    const tableRows = [];

    inputData.forEach((line) => {
      const row = [
        line.quantity || "1",
        line.description || "",
        line.price ? `€ ${parseFloat(line.price).toFixed(2)}` : "N/A",
      ];
      tableRows.push(row);
    });

    doc.autoTable({
      startY: 90,
      head: [tableColumnHeaders],
      body: tableRows,
      theme: 'grid',
      headStyles: { fillColor: [0, 123, 255] },
      margin: { top: 10 },
    });

    // Totale
    const total = inputData.reduce((acc, line) => acc + (parseFloat(line.price) || 0) * (parseInt(line.quantity) || 1), 0);
    doc.text(`Totale: € ${total.toFixed(2)}`, 150, doc.autoTable.previous.finalY + 20);

    return doc;
  } catch (error) {
    console.error('Errore durante la generazione del PDF:', error);
    throw error;
  }
};
