/**
 * Service class for managing shop-related API calls with caching
 */
class ShopService {
    constructor() {
        // Use the server URL directly without /api
        this.baseUrl = process.env.REACT_APP_SERVER_URL;
        this.shopId = process.env.REACT_APP_SHOP_ID;
        console.log('ShopService initialized with baseUrl:', this.baseUrl);
        console.log('ShopService initialized with shopId:', this.shopId);
        this.cache = null;
        this.lastFetch = null;
        this.CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 ore in millisecondi

        // Try to load cached data on initialization
        this.loadCachedData();
    }

    /**
     * Load cached data from localStorage
     */
    loadCachedData() {
        try {
            console.log('Checking for cached shop data...');
            const cachedData = localStorage.getItem('shopData');
            
            if (cachedData) {
                const { data, timestamp } = JSON.parse(cachedData);
                const now = new Date().getTime();
                const cacheAge = now - timestamp;
                const cacheAgeHours = Math.round(cacheAge / (60 * 60 * 1000) * 10) / 10;
                
                console.log(`Found cached data from ${cacheAgeHours} hours ago`);
                
                if (cacheAge < this.CACHE_DURATION) {
                    this.cache = data;
                    this.lastFetch = timestamp;
                    console.log('Cache is valid, data loaded:', this.cache);
                } else {
                    console.log(`Cache expired (${cacheAgeHours} hours old), will fetch fresh data`);
                    localStorage.removeItem('shopData');
                }
            } else {
                console.log('No cached data found');
            }
        } catch (error) {
            console.error('Error loading cached data:', error);
            localStorage.removeItem('shopData');
        }
    }

    /**
     * Save data to cache
     * @param {Object} data - Shop data to cache
     */
    saveToCache(data) {
        try {
            console.log('Saving new data to cache:', data);
            const cacheData = {
                data,
                timestamp: new Date().getTime()
            };
            localStorage.setItem('shopData', JSON.stringify(cacheData));
            this.cache = data;
            this.lastFetch = cacheData.timestamp;
            console.log('Data successfully saved to cache');
        } catch (error) {
            console.error('Error saving to cache:', error);
        }
    }

    /**
     * Check if cache is valid
     * @returns {boolean}
     */
    isCacheValid() {
        if (!this.cache || !this.lastFetch) {
            console.log('Cache not valid: No data or timestamp');
            return false;
        }
        const now = new Date().getTime();
        const cacheAge = now - this.lastFetch;
        const isValid = cacheAge < this.CACHE_DURATION;
        console.log(`Cache age: ${Math.round(cacheAge / (60 * 60 * 1000) * 10) / 10} hours, Valid: ${isValid}`);
        return isValid;
    }

    /**
     * Retrieves shop data by ID with caching
     * @param {number} id - Shop ID
     * @returns {Promise<Object>} Shop data
     */
    async getShopById(id) {
        console.log(`Getting shop data for ID: ${id}`);
        
        // Return cached data if valid
        if (this.isCacheValid()) {
            console.log('Using cached data:', this.cache);
            return this.cache;
        }

        try {
            console.log(`Fetching fresh data from server for shop ID: ${id}`);
            // Add headers for CORS and authentication
            const headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Origin': window.location.origin
            };

            // Add authorization header if token exists
            const token = localStorage.getItem('accessToken');
            if (token) {
                headers['Authorization'] = `Bearer ${token}`;
            }

            const response = await fetch(`${this.baseUrl}/shops/${id}`, {
                method: 'GET',
                headers,
                credentials: 'include'
            });
            
            console.log('Server response status:', response.status);
            
            if (!response.ok) {
                throw new Error(`Shop not found. Status: ${response.status}`);
            }
            
            const data = await response.json();
            console.log('Received fresh data from server:', data);
            this.saveToCache(data);
            return data;
        } catch (error) {
            console.error('Error fetching shop:', error);
            if (this.cache) {
                console.log('Network error, using cached data:', this.cache);
                return this.cache;
            }
            throw error;
        }
    }

    /**
     * Gets the default shop (shop with ID 1)
     * @returns {Promise<Object>} Default shop data
     */
    async getDefaultShop() {
        console.log('Getting default shop with ID:', this.shopId);
        try {
            return await this.getShopById(this.shopId);
        } catch (error) {
            console.error('Error in getDefaultShop:', error);
            throw error;
        }
    }

    /**
     * Force refresh cache
     */
    async refreshCache() {
        try {
            // Clear current cache
            this.cache = null;
            this.lastFetch = null;
            localStorage.removeItem('shopData');
            console.log('Cache cleared');

            // Force fetch new data
            const data = await this.getDefaultShop();
            console.log('New data fetched:', data);

            return data;
        } catch (error) {
            console.error('Error refreshing cache:', error);
            throw error;
        }
    }
}

// Export singleton instance
export default new ShopService();