// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import CassaPage from './pages/CassaPage';
import SetupPage from './pages/SetupPage';
import CustomerPage from './pages/CustomerPage';
import RiparazioniPage from './pages/RiparazioniPage';
import DocumentsPage from './pages/DocumentsPage';
import DDTPage from './pages/DDTPage';
import InvoicePage from './pages/InvoicePage';
import InternalDischargePage from './pages/InternalDischargePage';
import SidebarApp from './components/SidebarApp';
import PrivateRoute from './components/PrivateRoute';

import './App.css';

// Component to conditionally render sidebar
const AppContent = () => {
  const location = useLocation();
  
  // Check if current path is login page
  const isLoginPage = location.pathname === '/login' || location.pathname === '/';

  return (
    <div className="min-h-screen">
      {!isLoginPage && <SidebarApp />}
      <main className={!isLoginPage ? 'with-sidebar' : 'without-sidebar'}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          
          {/* Protected Routes */}
          <Route path="/home" element={<PrivateRoute><HomePage /></PrivateRoute>} />
          <Route path="/clienti" element={<PrivateRoute><CustomerPage /></PrivateRoute>} />
          <Route path="/cassa" element={<PrivateRoute><CassaPage /></PrivateRoute>} />
          <Route path="/riparazioni" element={<PrivateRoute><RiparazioniPage /></PrivateRoute>} />
          <Route path="/documents" element={<PrivateRoute><DocumentsPage /></PrivateRoute>} />
          <Route path="/ddt" element={<PrivateRoute><DDTPage /></PrivateRoute>} />
          <Route path="/ddt/:document_number" element={<PrivateRoute><DDTPage /></PrivateRoute>} />
          <Route path="/invoice" element={<PrivateRoute><InvoicePage /></PrivateRoute>} />
          <Route path="/invoice/:document_number" element={<PrivateRoute><InvoicePage /></PrivateRoute>} />
          <Route path="/internalDischarge" element={<PrivateRoute><InternalDischargePage /></PrivateRoute>} />
          <Route path="/setup" element={<PrivateRoute><SetupPage /></PrivateRoute>} />
          
          {/* Default Routes */}
          <Route path="/" element={<LoginPage />} />
          <Route path="*" element={<LoginPage />} />
        </Routes>
      </main>
    </div>
  );
};

// Main App component
const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
