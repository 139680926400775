import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import InvoiceCustomerData from '../components/InvoiceCustomerData';
import InvoiceDocumentData from '../components/InvoiceDocumentData';
import InvoiceInput from '../components/InvoiceInput';
import { generateInvoicePDF } from '../utils/InvoicePDF';
import { generateInvoiceXML } from '../utils/InvoiceXML';
import { FaFilePdf, FaFileCode } from 'react-icons/fa';
import ShopService from '../utils/ShopService';

const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';

const InvoicePage = () => {
  const location = useLocation();
  const [customerData, setCustomerData] = useState({});
  const [documentDetails, setDocumentDetails] = useState({});
  const [documentLines, setDocumentLines] = useState([]);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [pdfPath, setPdfPath] = useState(null);
  const [xmlPath, setXmlPath] = useState(null);
  const [vatRate, setVatRate] = useState(22); // Default VAT rate
  const navigate = useNavigate();

  useEffect(() => {
    const fetchShopData = async () => {
      try {
        const shopData = await ShopService.getDefaultShop();
        if (shopData && shopData.vatRate) {
          setVatRate(parseFloat(shopData.vatRate));
        }
      } catch (error) {
        console.error('Error fetching shop data:', error);
      }
    };
    fetchShopData();

    const fetchNextDocumentNumber = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        if (!token) {
          console.error('Token non trovato');
          navigate('/login');
          return;
        }

        const response = await axios.get(`${serverUrl}/invoices/next-document-number`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        if (response.data) {
          setDocumentDetails(prev => ({
            ...prev,
            document_number: response.data
          }));
        }
      } catch (error) {
        console.error('Errore nel recupero del numero documento:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
        setMessage('Errore nel recupero del numero documento');
        setMessageType('error');
      }
    };

    // Chiama la funzione solo se non abbiamo già un numero documento
    if (!documentDetails.document_number) {
      fetchNextDocumentNumber();
    }

    if (location.state && location.state.selectedRepairs) {
      setDocumentLines((prevLines) => {
        if (prevLines.length === 0) {
          return location.state.selectedRepairs.flatMap((repair) =>
            repair.lines.map((line) => ({
              quantity: line.quantity || 1,
              description: `Riparazione ${line.id_repair} ${line.description}` || '',
              price: line.price || 0,
            }))
          );
        }
        return prevLines;
      });
    }

    if (location.state && location.state.notes) {
      setDocumentDetails((prevDetails) => {
        if (prevDetails.notes !== location.state.notes) {
          return {
            ...prevDetails,
            notes: location.state.notes,
          };
        }
        return prevDetails;
      });
    }
  }, [navigate, location.state]);

  const calculateTotalPrice = () => {
    return documentLines.reduce((total, line) => {
      return total + line.quantity * line.price;
    }, 0);
  };

  const handleSaveInvoice = async () => {
    try {
      if (!documentDetails.document_number) {
        setMessage('Numero del documento obbligatorio.');
        setMessageType('error');
        return;
      }
      if (!documentDetails.creation_date) {
        setMessage('Data di creazione obbligatoria.');
        setMessageType('error');
        return;
      }
      if (!customerData.id_customer) {
        setMessage('Selezionare un cliente.');
        setMessageType('error');
        return;
      }
      if (!documentDetails.payment_id) {
        setMessage('Tipo di pagamento obbligatorio.');
        setMessageType('error');
        return;
      }

      const total_price = calculateTotalPrice();
      const vat = (total_price * vatRate) / 100;
      const total_with_vat = total_price + vat;

      const invoiceData = {
        document_number: documentDetails.document_number,
        creation_date: documentDetails.creation_date,
        id_customer: customerData.id_customer,
        codiceSdi: customerData.codiceSdi,
        payment_id: documentDetails.payment_id,
        payment_type: documentDetails.payment_type,
        codePaymentXml: documentDetails.codePaymentXml,
        price: total_price,
        notes: documentDetails.notes,
        vat: vat,
        total_price: total_with_vat,
        lines: documentLines.map((item) => ({
          description: item.description,
          quantity: item.quantity,
          price: item.price,
        })),
      };

      console.log('Dati fattura che inviamo al server:', invoiceData);

      const token = localStorage.getItem('accessToken');
      if (!token) {
        navigate('/login');
        throw new Error('Token non trovato');
      }

      const headers = {
        'Authorization': `Bearer ${token}`
      };

      // Prima di inviare la fattura al server, generiamo PDF e XML
      const pdfBlob = await generateInvoicePDF(invoiceData, customerData);
      const xmlBlob = await generateInvoiceXML(invoiceData, customerData);

      // Ora dobbiamo inviare i file al server
      const formData = new FormData();
      formData.append('pdf', pdfBlob, `${documentDetails.document_number}.pdf`);
      formData.append('xml', xmlBlob, `${documentDetails.document_number}.xml`);

      // Salviamo i file sul server in una directory specifica
      const fileUploadResponse = await axios.post(`${serverUrl}/invoices/upload-files`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...headers
        },
      });

      // Aggiungi i percorsi dei file all'invoiceData
      invoiceData.pdf_path = fileUploadResponse.data.pdfPath;
      invoiceData.xml_path = fileUploadResponse.data.xmlPath;

      // Ora salva la fattura con i percorsi dei file generati
      const saveInvoiceResponse = await axios.post(`${serverUrl}/invoices`, invoiceData, {
        headers
      });
      console.log('Risposta del server:', saveInvoiceResponse);
      
      // Imposta i percorsi dei file per visualizzare i pulsanti PDF e XML
      setPdfPath(invoiceData.pdf_path);
      setXmlPath(invoiceData.xml_path);

      setMessage('Fattura salvata con successo!');
      setMessageType('success');
    } catch (error) {
      console.error('Errore durante il salvataggio della fattura:', error);
      setMessage('Errore durante il salvataggio della fattura.');
      setMessageType('error');
    }
  };

  const handleDownloadPdf = () => {
    if (pdfPath) {
      window.open(`${serverUrl}${pdfPath}`, '_blank');
    }
  };

  const handleDownloadXml = () => {
    if (xmlPath) {
      axios
        .get(`${serverUrl}${xmlPath}`, {
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/xml' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${documentDetails.document_number}.xml`);
          link.click();
        })
        .catch((error) => {
          console.error('Errore durante il download del file XML:', error);
          setMessage('Errore durante il download del file XML.');
          setMessageType('error');
        });
    }
  };

  // Handle exit from the page
  const handleExit = () => {
    if (location.state?.from) {
      navigate(location.state.from); // Torna alla pagina di provenienza
    } else {
      navigate('/Home'); // Vai alla home se non c'è stato di provenienza
    }
  };

  return (
    <div className="p-5 max-w-6xl mx-auto bg-white rounded-lg shadow-lg flex flex-col min-h-screen">
      <h1 className="text-2xl font-bold mb-5">Fattura</h1>
  
      {/* Container per Dati Cliente e Dati Fattura */}
      <div className="flex flex-wrap lg:flex-nowrap gap-5 mb-5">
        <div className="w-full lg:w-1/2 bg-blue-50 p-5 rounded-lg shadow-md">
          <InvoiceCustomerData customerData={customerData} setCustomerData={setCustomerData} />
        </div>
        <div className="w-full lg:w-1/2 bg-blue-50 p-5 rounded-lg shadow-md">
          <InvoiceDocumentData documentDetails={documentDetails} setDocumentDetails={setDocumentDetails} />
        </div>
      </div>
  
      {/* Invoice Input al di sotto dei due box */}
      <div className="mb-5">
        <InvoiceInput documentLines={documentLines} setDocumentLines={setDocumentLines} />
      </div>
  
      {/* Messaggio di conferma o errore */}
      {message && (
        <div
          className={`mt-5 p-3 rounded text-lg ${
            messageType === 'error'
              ? 'bg-red-200 text-red-800 border border-red-400'
              : 'bg-green-200 text-green-800 border border-green-400'
          }`}
        >
          {message}
        </div>
      )}
  
      {/* Azioni della fattura */}
      <div className="flex justify-end gap-3 mt-8 fixed bottom-0 left-0 right-0 bg-white p-4 shadow-lg">
        <button onClick={handleSaveInvoice} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300">
          Salva Fattura
        </button>
        {pdfPath && (
          <button onClick={handleDownloadPdf} className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 transition duration-300 flex items-center gap-2">
            <FaFilePdf /> Scarica PDF
          </button>
        )}
        {xmlPath && (
          <button onClick={handleDownloadXml} className="px-4 py-2 bg-indigo-500 text-white rounded hover:bg-indigo-700 transition duration-300 flex items-center gap-2">
            <FaFileCode /> Scarica XML
          </button>
        )}
        <button onClick={handleExit} className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-800 transition duration-300">
          Esci
        </button>
      </div>
    </div>
  );
};

export default InvoicePage;
