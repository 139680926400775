import React from 'react';
import {  useNavigate } from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="home-page flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold mb-10 text-blue-700">Gestione Ticket</h1>
      <div className="buttons-container grid grid-cols-2 gap-8 md:grid-cols-2 lg:grid-cols-2">
        <button
          onClick={() => navigate('/clienti', { state: { from: '/Home' } })}
          className="home-button w-56 h-56 text-2xl bg-blue-500 text-white rounded-3xl shadow-lg hover:bg-blue-700 transition duration-300 ease-in-out"
        >
          Clienti
        </button>
        <button
          onClick={() => navigate('/cassa', { state: { from: '/Cassa' } })}
          className="home-button w-56 h-56 text-2xl bg-blue-500 text-white rounded-3xl shadow-lg hover:bg-blue-700 transition duration-300 ease-in-out"
        >
          Cassa
        </button>
        <button
          onClick={() => navigate('/Riparazioni', { state: { from: '/Home' } })}
          className="home-button w-56 h-56 text-2xl bg-green-500 text-white rounded-3xl shadow-lg hover:bg-green-700 transition duration-300 ease-in-out"
        >
          Riparazioni
        </button>
        <button
          onClick={() => navigate('/Documents', { state: { from: '/Home' } })}
          className="home-button w-56 h-56 text-2xl bg-yellow-500 text-white rounded-3xl shadow-lg hover:bg-yellow-700 transition duration-300 ease-in-out"
        >
          Documenti
        </button>
        <button className="home-button w-56 h-56 text-2xl bg-red-500 text-white rounded-3xl shadow-lg hover:bg-red-700 transition duration-300 ease-in-out">
          Statistiche
        </button>
        
      </div>
    </div>
  );
};

export default HomePage;
