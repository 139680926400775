// DocumentsTable.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './DocumentsTable.css';
import { useNavigate } from 'react-router-dom';
import { FaTrash, FaFilePdf, FaFileCode } from 'react-icons/fa';

const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';

const DocumentsTable = () => {
  const [documents, setDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await axios.get(`${serverUrl}/documents`);
        setDocuments(response.data);
      } catch (error) {
        console.error('Errore nel recupero dei documenti:', error);
        setMessage('Errore nel recupero dei documenti.');
      }
    };

    fetchDocuments();
  }, []);

  const handleRowClick = (e, document) => {
    if (e.type === 'contextmenu') {
      e.preventDefault();
      setContextMenu({
        mouseX: e.clientX - 2,
        mouseY: e.clientY - 4,
        document,
      });
    }
  };

  const handleSendToInvoice = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    const selectedDDTs = selectedDocuments.filter((doc) => doc.type === 'DDT');
    if (selectedDDTs.length === 0) {
      alert('Selezionare almeno un documento DDT per procedere con la fatturazione.');
      setIsLoading(false);
      return;
    }

    try {
      const documentNumbers = selectedDDTs.map((ddt) => ddt.document_number);
      const payload = { documentNumbers };
      const response = await axios.post(`${serverUrl}/ddts/lines`, payload);
      const repairLines = response.data;

      navigate('/invoice', {
        state: {
          selectedRepairs: repairLines,
          notes: `Riferimento vostri DDT:\n${selectedDDTs
            .map(
              (ddt) =>
                `- DDT ${ddt.document_number} del ${new Date(
                  ddt.creation_date
                ).toLocaleDateString()}`
            )
            .join('\n')}`,
        },
      });
      handleCloseContextMenu();
    } catch (error) {
      console.error('Errore durante il recupero delle righe delle riparazioni:', error);
      alert('Errore durante il recupero delle righe delle riparazioni.');
    }

    setIsLoading(false);
  };

  const handleCheckboxChange = (document) => {
    setSelectedDocuments((prevSelected) => {
      if (prevSelected.includes(document)) {
        return prevSelected.filter((doc) => doc !== document);
      } else {
        return [...prevSelected, document];
      }
    });
  };

  const handleDeleteDocument = async (document) => {
    const confirmDelete = window.confirm(
      `Sei sicuro di voler eliminare il documento ${document.document_number}?`
    );
    if (confirmDelete) {
      try {
        // Determina l'endpoint corretto in base al tipo di documento
        let endpoint;
        switch (document.type) {
          case 'Invoice':
            endpoint = 'invoices';
            break;
          case 'DDT':
            endpoint = 'ddts';
            break;
          case 'Internal Discharge':
          case 'InternalDischarge':
            endpoint = 'internal-discharges';
            break;
          default:
            alert('Tipo di documento non supportato per l\'eliminazione.');
            return;
        }
  
        // Invio richiesta DELETE per il documento specifico
        await axios.delete(`${serverUrl}/${endpoint}/${document.document_number}`);
  
        // Rimuovi il documento dalla lista attuale dopo l'eliminazione
        setDocuments((prevDocuments) =>
          prevDocuments.filter((doc) => doc.document_number !== document.document_number)
        );
  
        setMessage('Documento eliminato con successo.');
      } catch (error) {
        console.error('Errore durante l\'eliminazione del documento:', error);
        setMessage('Errore durante l\'eliminazione del documento.');
      }
    }
  };
  

  const handleDownloadPdf = async (docItem) => {
    try {
      let endpoint;
      switch (docItem.type) {
        case 'Invoice':
          endpoint = 'invoices';
          break;
        case 'DDT':
          endpoint = 'ddts';
          break;
        case 'Internal Discharge':
        case 'InternalDischarge':
          endpoint = 'internal-discharges';
          break;
        default:
          alert('Tipo di documento non supportato.');
          return;
      }
  
      const response = await axios.get(
        `${serverUrl}/${endpoint}/${docItem.document_number}/pdf`,
        {
          responseType: 'blob',
        }
      );
  
      const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(pdfBlob);
      link.download = `${docItem.document_number}.pdf`;
      link.click();
    } catch (error) {
      console.error('Errore durante il download del PDF:', error);
      alert('Errore durante il download del PDF.');
    }
  };
  
  
  
  const handleDownloadXml = async (docItem) => {
    try {
      if (docItem.type !== 'Invoice') {
        alert('Solo le fatture hanno un file XML associato.');
        return;
      }
  
      // Richiede l'XML al server
      const response = await axios.get(
        `${serverUrl}/invoices/${docItem.document_number}/xml`,
        {
          responseType: 'blob',
        }
      );
  
      // Crea un oggetto Blob con il contenuto XML ricevuto
      const xmlBlob = new Blob([response.data], { type: 'application/xml' });
      // Crea un link per scaricare il file
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(xmlBlob);
      link.setAttribute('download', `${docItem.document_number}.xml`);
      document.body.appendChild(link);
      // Simula il click sul link per aprire il dialogo di salvataggio
      link.click();
      // Rimuove il link dopo l'uso
      document.body.removeChild(link);
    } catch (error) {
      console.error('Errore durante il download dell\'XML:', error);
      alert('Errore durante il download dell\'XML.');
    }
  };
  
  

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  // Function to translate document types
  const translateDocumentType = (type) => {
    switch (type) {
      case 'Invoice':
        return 'Fattura';
      case 'InternalDischarge':
      case 'Internal Discharge':
        return 'S.I.';
      default:
        return type;
    }
  };

  return (
    <div className="documents-table-container">
      <h1>Lista Documenti</h1>
      {message && <div className="error-message">{message}</div>}
      <table className="documents-table">
        <thead>
          <tr>
            <th>Seleziona</th>
            <th>Tipo Documento</th>
            <th>Numero Documento</th>
            <th>Cliente</th>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>
          {documents.length > 0 ? (
            documents.map((doc, index) => (
              <tr key={index} onContextMenu={(e) => handleRowClick(e, doc)}>
                <td>
                  <input
                    type="checkbox"
                    checked={selectedDocuments.includes(doc)}
                    onChange={() => handleCheckboxChange(doc)}
                  />
                </td>
                <td>{translateDocumentType(doc.type)}</td>
                <td>{doc.document_number}</td>
                <td>{doc.companyName || 'N/A'}</td>
                <td>
                  {(doc.type === 'DDT' || doc.type === 'Internal Discharge' || doc.type === 'InternalDischarge') && (
                    <button
                      onClick={() => handleDownloadPdf(doc)}
                      className="action-button pdf-button"
                    >
                      <FaFilePdf />
                    </button>
                  )}
                  {doc.type === 'Invoice' && (
                    <>
                      <button
                        onClick={() => handleDownloadPdf(doc)}
                        className="action-button pdf-button"
                      >
                        <FaFilePdf />
                      </button>
                      <button
                        onClick={() => handleDownloadXml(doc)}
                        className="action-button xml-button"
                      >
                        <FaFileCode />
                      </button>
                    </>
                  )}
                  <button
                    onClick={() => handleDeleteDocument(doc)}
                    className="action-button delete-button"
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">Nessun documento trovato.</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Context menu */}
      {contextMenu && (
        <div
          className="context-menu"
          style={{
            top: contextMenu.mouseY,
            left: contextMenu.mouseX,
          }}
        >
          <ul>
            <li onClick={handleSendToInvoice}>Invia a Fattura</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default DocumentsTable;
