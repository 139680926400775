// Import necessary libraries
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ShopService from "../utils/ShopService";
import CloseScontrinoModal from '../components/CloseScontrinoModal';

const CassaPage = () => {
  const [users, setUsers] = useState([]); 
  const [selectedUser, setSelectedUser] = useState(""); 
  const [searchQuery, setSearchQuery] = useState(""); 
  const [clientSuggestions, setClientSuggestions] = useState([]); 
  const [isLoading, setIsLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [repairs, setRepairs] = useState([]);
  const [searchRepair, setSearchRepair] = useState("");
  const [loading, setLoading] = useState(true);
  const [cartRepairs, setCartRepairs] = useState([]); 
  const [isCloseScontrinoModalOpen, setIsCloseScontrinoModalOpen] = useState(false);
  const [shopData, setShopData] = useState(null); 
  const navigate = useNavigate();

  const getVATRate = () => {
    return shopData ? parseFloat(shopData.iva) / 100 : 0;
  };

  // Calcola il prezzo netto da quello lordo
  const calculateNetPrice = (grossPrice) => {
    const vatRate = getVATRate();
    return vatRate ? parseFloat(grossPrice) / (1 + vatRate) : parseFloat(grossPrice);
  };

  // Calcola il prezzo lordo da quello netto
  const calculateGrossPrice = (netPrice) => {
    const vatRate = getVATRate();
    return vatRate ? parseFloat(netPrice) * (1 + vatRate) : parseFloat(netPrice);
  };

  // Calcola l'IVA
  const calculateVAT = (price, isGross = true) => {
    const vatRate = getVATRate();
    const netPrice = isGross ? calculateNetPrice(price) : price;
    return netPrice * vatRate;
  };

  // Gestisce il prezzo da mostrare in base alle impostazioni
  const getDisplayPrice = (price) => {
    const showNetPrice = localStorage.getItem('showNetPrice') === 'true';
    return showNetPrice ? calculateNetPrice(price) : parseFloat(price);
  };

  // Calcola il totale del carrello
  const calculateCartTotal = () => {
    const showNetPrice = localStorage.getItem('showNetPrice') === 'true';
    return cartRepairs.reduce((sum, repair) => {
      const price = showNetPrice ? calculateNetPrice(repair.public_price) : parseFloat(repair.public_price);
      return sum + price;
    }, 0);
  };

  // Gestisce l'aggiunta di una riparazione al carrello
  const handleAddToCart = (repair) => {
    const showNetPrice = localStorage.getItem('showNetPrice') === 'true';
    const modifiedRepair = {
      ...repair,
      original_price: repair.public_price, // Manteniamo il prezzo originale
      public_price: showNetPrice ? calculateNetPrice(repair.public_price) : repair.public_price
    };
    setCartRepairs(prev => [...prev, modifiedRepair]);
    setRepairs([]);
    setSearchRepair("");
  };

  // Formatta il prezzo per la visualizzazione
  const formatPrice = (price) => {
    return parseFloat(price).toFixed(2);
  };

  // Prepara i dati per lo scontrino
  const prepareReceiptData = () => {
    const showNetPrice = localStorage.getItem('showNetPrice') === 'true';
    return cartRepairs.map(repair => ({
      ...repair,
      public_price: showNetPrice ? calculateGrossPrice(repair.public_price) : repair.public_price
    }));
  };

  useEffect(() => {
    const fetchShopData = async () => {
      try {
        const data = await ShopService.getDefaultShop();
        setShopData(data);
      } catch (error) {
        console.error('Error loading shop data:', error);
      }
    };
    fetchShopData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';
        const [usersResponse] = await Promise.all([
          axios.get(`${serverUrl}/users`)
        ]);
        setUsers(usersResponse.data);
        setLoading(false);
      } catch (error) {
        console.error('Errore durante il caricamento dei dati:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleUserChange = (e) => {
    setSelectedUser(e.target.value);
  };

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    if (query.length >= 3) {
      setIsLoading(true);
      const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';
      axios
        .get(`${serverUrl}/customers?query=${query}`)
        .then((response) => {
          setClientSuggestions(response.data);
          setNotFound(response.data.length === 0);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching customers:", error);
          setIsLoading(false);
        });
    } else {
      setClientSuggestions([]);
      setNotFound(false);
    }
  };

  const handleSelectCustomer = (customer) => {
    setSearchQuery(customer.companyName);
    setClientSuggestions([]);
  };

  useEffect(() => {
    if (searchRepair.length > 0) {
      const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';
      axios
        .get(`${serverUrl}/repairs/${searchRepair}`)
        .then((response) => {
          if (response.data) {
            setRepairs([response.data]);
          } else {
            setRepairs([]);
          }
        })
        .catch((error) => {
          console.error("Error fetching repairs:", error);
          setRepairs([]);
        });
    } else {
      setRepairs([]);
    }
  }, [searchRepair]);

  const handleRemoveFromCart = (repairId) => {
    setCartRepairs(prev => prev.filter(repair => repair.id_repair !== repairId));
  };

  const handleConfirmReceipt = () => {
    if (cartRepairs.length === 0) {
      alert("Aggiungi almeno una riparazione allo scontrino");
      return;
    }
    setIsCloseScontrinoModalOpen(true);
  };

  const handleCloseReceipt = async () => {
    setIsCloseScontrinoModalOpen(false);
    setCartRepairs([]);
  };

  const handleExit = () => {
    window.history.back();
  };

  if (loading) {
    return <div>Caricamento...</div>;
  }

  return (
    <div className="p-6 space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Operatore</label>
        <select
          name="id_user"
          value={selectedUser}
          onChange={handleUserChange}
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
        >
          <option value="">Seleziona un operatore</option>
          {users.map((user) => (
            <option key={user.id_user} value={user.id_user}>
              {user.name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Cliente</label>
        <div className="flex items-center space-x-2">
          <input
            type="text"
            placeholder="Ricerca rapido cliente"
            value={searchQuery}
            onChange={handleInputChange}
            className="flex-grow p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            onClick={() => navigate('/clienti', { state: { from: '/Cassa' } })}
            className="p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
          >
            Aggiungi Nuovo Cliente
          </button>
        </div>

        {isLoading && <p className="text-blue-500">Caricamento in corso...</p>}

        {!isLoading && clientSuggestions.length > 0 && (
          <ul className="border border-gray-300 rounded-lg mt-2">
            {clientSuggestions.map((suggestion) => (
              <li
                key={suggestion.id_customer}
                onClick={() => handleSelectCustomer(suggestion)}
                className="p-2 cursor-pointer hover:bg-blue-100"
              >
                {suggestion.companyName}
              </li>
            ))}
          </ul>
        )}

        {!isLoading && notFound && (
          <p className="text-red-500 mt-2">Cliente non trovato.</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Cerca Riparazione per Codice</label>
        <input
          type="text"
          placeholder="Inserisci codice riparazione..."
          value={searchRepair}
          onChange={(e) => setSearchRepair(e.target.value)}
          className="w-full mt-1 p-2 border border-gray-300 rounded-md"
        />
      </div>

      {repairs.length > 0 && (
        <div className="mt-4">
          <h3 className="text-lg font-medium mb-2">Risultati Ricerca</h3>
          <table className="min-w-full border border-gray-300">
            <thead>
              <tr>
                <th className="p-2 border border-gray-300">Codice</th>
                <th className="p-2 border border-gray-300">Descrizione</th>
                <th className="p-2 border border-gray-300">Prezzo</th>
                <th className="p-2 border border-gray-300">Stato</th>
                <th className="p-2 border border-gray-300">Azioni</th>
              </tr>
            </thead>
            <tbody>
              {repairs.map((repair) => (
                <tr key={repair.id_repair}>
                  <td className="p-2 border border-gray-300">{repair.id_repair}</td>
                  <td className="p-2 border border-gray-300">{repair.description}</td>
                  <td className="p-2 border border-gray-300">{repair.public_price}€</td>
                  <td className="p-2 border border-gray-300">{repair.current_status}</td>
                  <td className="p-2 border border-gray-300">
                    <button
                      onClick={() => handleAddToCart(repair)}
                      className="bg-green-500 text-white px-4 py-1 rounded hover:bg-green-600"
                    >
                      Aggiungi allo Scontrino
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {cartRepairs.length > 0 && (
        <div className="mt-8">
          <h3 className="text-lg font-medium mb-2">Riparazioni nello Scontrino</h3>
          <table className="min-w-full border border-gray-300">
            <thead>
              <tr>
                <th className="p-2 border border-gray-300">Codice</th>
                <th className="p-2 border border-gray-300">Descrizione</th>
                <th className="p-2 border border-gray-300">Prezzo</th>
                <th className="p-2 border border-gray-300">IVA ({shopData && shopData.iva}%)</th>
                <th className="p-2 border border-gray-300">Totale</th>
                <th className="p-2 border border-gray-300">Azioni</th>
              </tr>
            </thead>
            <tbody>
              {cartRepairs.map((repair, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="p-2 border border-gray-300">{repair.id}</td>
                  <td className="p-2 border border-gray-300">{repair.description}</td>
                  <td className="p-2 border border-gray-300">€ {formatPrice(getDisplayPrice(repair.public_price))}</td>
                  <td className="p-2 border border-gray-300">€ {calculateVAT(repair.public_price).toFixed(2)}</td>
                  <td className="p-2 border border-gray-300">€ {formatPrice(parseFloat(repair.public_price))}</td>
                  <td className="p-2 border border-gray-300">
                    <button
                      onClick={() => handleRemoveFromCart(repair.id_repair)}
                      className="bg-red-500 text-white px-4 py-1 rounded hover:bg-red-600"
                    >
                      Rimuovi
                    </button>
                  </td>
                </tr>
              ))}
              <tr className="bg-gray-100 font-bold">
                <td colSpan="4" className="p-2 border border-gray-300 text-right">Totale Scontrino:</td>
                <td className="p-2 border border-gray-300">{formatPrice(calculateCartTotal())}€</td>
                <td className="p-2 border border-gray-300"></td>
              </tr>
            </tbody>
          </table>
        </div>
      )}

      <div className="flex justify-between mt-4">
        <button className="bg-blue-500 text-white py-2 px-4 rounded">Sconto a Valore</button>
        <button className="bg-blue-500 text-white py-2 px-4 rounded">Sconto Percentuale</button>
        <button className="bg-red-500 text-white py-2 px-4 rounded">Annulla Scontrino</button>
        <button
          className="bg-green-500 text-white py-2 px-4 rounded"
          onClick={handleConfirmReceipt}
        >
          Conferma
        </button>
        <button
          className="bg-gray-500 text-white py-2 px-4 rounded"
          onClick={handleExit}
        >
          Esci
        </button>
      </div>

      {isCloseScontrinoModalOpen && (
        <CloseScontrinoModal
          isOpen={isCloseScontrinoModalOpen}
          onClose={() => setIsCloseScontrinoModalOpen(false)}
          cartItems={prepareReceiptData()}
          total={calculateCartTotal()}
          shopId={shopData?.id}
        />
      )}
    </div>
  );
};

export default CassaPage;
