// DDTPDF.js
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ShopService from './ShopService';

export const generateDDTPDF = async (documentDetails, customerData, inputData) => {
  console.log('Starting DDT PDF generation with:', { documentDetails, customerData, inputData });
  const doc = new jsPDF();

  try {
    // Get shop data
    console.log('Fetching shop data...');
    const shopData = await ShopService.getDefaultShop();
    console.log('Raw shop data received:', shopData);

    // Ensure we have valid shop data
    if (!shopData) {
      console.error('Shop data is null or undefined');
      throw new Error('Dati del negozio non trovati');
    }

    // Extract and map shop data to the correct fields
    const shopInfo = {
      name: shopData.name || '',
      address: shopData.address || '',
      city: shopData.city || '',
      province: shopData.province || '',
      cap: shopData.cap || '',
      vatInfo: shopData.vatInfo || '', // Use fiscalcode or IVA
    };
    
    // Log mapped data
    console.log('Mapped shop data:', shopInfo);

    // Verify each field individually
    if (!shopInfo.name) throw new Error('Nome negozio mancante');
    if (!shopInfo.address) throw new Error('Indirizzo negozio mancante');
    if (!shopInfo.city) throw new Error('Città negozio mancante');
    if (!shopInfo.province) throw new Error('Provincia negozio mancante');
    if (!shopInfo.vatInfo) throw new Error('P.IVA/Codice Fiscale negozio mancante');

    console.log('All shop data validated successfully');

    // Header - Mittente
    doc.setFontSize(14);
    doc.text(shopInfo.name.trim(), 10, 10);
    doc.setFontSize(12);
    doc.text(shopInfo.address.trim(), 10, 15);
    doc.text(`${shopInfo.cap.trim()} ${shopInfo.city.trim()} (${shopInfo.province.trim()})`, 10, 20);
    doc.text(`P.I e CF: ${shopInfo.vatInfo.trim()}`, 10, 25);

    // Validate customer data
    if (!customerData) {
      console.error('Customer data is missing');
      throw new Error('Dati cliente mancanti');
    }

    // Customer Info
    doc.setFontSize(12);
    doc.text("Destinatario:", 10, 50);
    doc.text(customerData.companyName || 'N/D', 10, 55);
    doc.text(customerData.address || 'N/D', 10, 60);
    doc.text([
      customerData.cap || 'N/D',
      customerData.citta || 'N/D',
      customerData.provincia || 'N/D',
      customerData.nazione || 'N/D'
    ].filter(Boolean).join(' '), 10, 65);
    
    doc.text(`Telefono: ${customerData.phone || 'N/D'}`, 10, 70);
    doc.text(`Email: ${customerData.email || 'N/D'}`, 10, 75);
    if (customerData.pec) {
      doc.text(`PEC: ${customerData.pec}`, 10, 80);
    }

    // Document Details
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    doc.text("Tipo Documento: DDT", 140, 50);
    doc.text(`Nr: ${documentDetails.document_number || 'N/A'}`, 120, 55);
    doc.text(`Data: ${documentDetails.creation_date ? formatDate(documentDetails.creation_date) : 'N/A'}`, 120, 60);

    // Use default value 'Reso' if transport_reason is missing
    doc.text(`Causale Trasporto: ${documentDetails.transport_reason || 'Reso'}`, 120, 65);

    // Use default value 'A vista' if appearance_of_goods is missing
    doc.text(`Aspetto Esteriore Dei Beni: ${documentDetails.appearance_of_goods || 'A vista'}`, 120, 70);

    doc.text(`N. Colli: ${documentDetails.number_of_packages || 'N/A'}`, 120, 75);
    doc.text(`Peso Kg: ${documentDetails.weight || 'N/A'}`, 120, 80);

    // Use default value 'Assegnato' if shipment_port is missing
    doc.text(`Porto: ${documentDetails.shipment_port || 'Assegnato'}`, 120, 85);

    // Use default value 'Nessuno' if courier is missing
    doc.text(`Corriere: ${documentDetails.courier || 'Nessuno'}`, 120, 90); 

    // Table - Document Lines
    const tableColumnHeaders = ["Quantità", "Descrizione", "Prezzo"];
    const tableRows = [];

    inputData.forEach((line) => {
      const row = [
        line.quantity || "1",
        line.description || "",
        line.price ? `€ ${parseFloat(line.price).toFixed(2)}` : "0",
      ];
      tableRows.push(row);
    });

    doc.autoTable({
      startY: 100,
      head: [tableColumnHeaders],
      body: tableRows,
      theme: 'grid',
      headStyles: { fillColor: [0, 123, 255] },
      margin: { top: 10 },
    });

    // Footer
    const finalY = doc.autoTable.previous.finalY || 100;
    doc.text("Note:", 10, finalY + 10);
    doc.text(documentDetails.notes || "", 10, finalY + 20);

    doc.text("Trasporto a cura del:", 10, finalY + 40);
    doc.text(documentDetails.transport_by || 'N/A', 60, finalY + 40);

    doc.text("Firma Mittente", 10, finalY + 60);
    doc.text("__________________", 10, finalY + 65);

    doc.text("Firma Vettore", 80, finalY + 60);
    doc.text("__________________", 80, finalY + 65);

    doc.text("Firma Destinatario", 150, finalY + 60);
    doc.text("__________________", 150, finalY + 65);

    // Get PDF as array buffer
    const pdfOutput = doc.output('arraybuffer');
    console.log('PDF generation completed, size:', pdfOutput.byteLength, 'bytes');
    
    return pdfOutput;
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  }
};
