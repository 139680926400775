// Assicurati che il componente PrivateRoute controlli correttamente l'autenticazione
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ children }) => {
    const token = localStorage.getItem('accessToken'); // Usa la stessa chiave usata per salvare il token
  
    // Se non esiste il token, reindirizza al login
    if (!token) {
      console.log('Token non trovato, reindirizzamento al login');
      return <Navigate to="/login" />;
    }
  
    // Se il token esiste, consenti l'accesso alla pagina protetta
    return children;
  };
  
  export default PrivateRoute;
  
