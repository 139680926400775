import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome, FaTools, FaFileAlt, FaCog, FaSignOutAlt } from 'react-icons/fa';

const SidebarApp = () => {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef(null); // Ref per la sidebar
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    setIsOpen(false); // Chiude la sidebar
  };

  // Funzione per chiudere la sidebar cliccando fuori
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        closeSidebar(); // Chiude la sidebar se si clicca fuori
      }
    };

    // Aggiunge l'evento per rilevare i clic esterni
    document.addEventListener('mousedown', handleClickOutside);

    // Rimuove l'evento quando il componente viene smontato
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle logout action
  const handleLogout = () => {
    // Clear any stored authentication data
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    
    // Redirect to login page
    navigate('/login');
  };

  return (
    <div ref={sidebarRef} className="relative">
      {/* Hamburger Icon */}
      <div
        className="hamburger cursor-pointer p-4 z-50 fixed top-4 left-4 bg-gray-800 rounded-md text-white"
        onClick={toggleSidebar}
      >
        <div className="w-6 h-1 bg-white my-1"></div>
        <div className="w-6 h-1 bg-white my-1"></div>
        <div className="w-6 h-1 bg-white my-1"></div>
      </div>

      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full w-64 bg-gray-900 text-white z-40 transform transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <nav className="p-6">
          <ul className="space-y-4">
            <li>
              <Link
                to="/Home"
                className="block text-lg font-medium hover:bg-gray-700 p-3 rounded transition duration-200"
                onClick={closeSidebar}
              >
                <FaHome className="inline-block mr-2" /> Dashboard
              </Link>
            </li>
            <li>
              <Link
                to="/riparazioni"
                className="block text-lg font-medium hover:bg-gray-700 p-3 rounded transition duration-200"
                onClick={closeSidebar}
              >
                <FaTools className="inline-block mr-2" /> Riparazioni
              </Link>
            </li>
            <li>
              <Link
                to="/scadenziario"
                className="block text-lg font-medium hover:bg-gray-700 p-3 rounded transition duration-200"
                onClick={closeSidebar}
              >
                <FaFileAlt className="inline-block mr-2" /> Scadenziario
              </Link>
            </li>
            <li>
              <Link
                to="/setup"
                className="block text-lg font-medium hover:bg-gray-700 p-3 rounded transition duration-200"
                onClick={closeSidebar}
              >
                <FaCog className="inline-block mr-2" /> Impostazioni
              </Link>
            </li>
          </ul>
        </nav>

        {/* Logout button at the bottom */}
        <button
          onClick={handleLogout}
          className="block w-full text-lg font-medium hover:bg-red-700 p-3 rounded transition duration-200 bg-red-600 mt-4"
        >
          <FaSignOutAlt className="inline-block mr-2" /> Logout
        </button>
      </div>
    </div>
  );
};

export default SidebarApp;
