import React, { useState } from 'react';

const SidebarRepair = ({ onClose, onSearch, customers, laboratories, isOpen }) => {
  const [filters, setFilters] = useState({
    id_repair: '',
    externalCode: '',
    id_customer: '',
    id_laboratory: '',
    description: '',
    current_status: '',
    entry_date_start: '',
    entry_date_end: '',
    delivery_date_start: '',
    delivery_date_end: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleSearchClick = () => {
    onSearch(filters);
    onClose();
  };

  const handleReset = () => {
    setFilters({
      id_repair: '',
      externalCode: '',
      id_customer: '',
      id_laboratory: '',
      description: '',
      current_status: '',
      entry_date_start: '',
      entry_date_end: '',
      delivery_date_start: '',
      delivery_date_end: ''
    });
  };

  return (
    <div 
      className={`fixed inset-y-0 right-0 w-96 bg-white shadow-2xl transform transition-transform duration-300 ease-in-out ${
        isOpen ? 'translate-x-0' : 'translate-x-full'
      } overflow-y-auto`}
    >
      {/* Header */}
      <div className="sticky top-0 bg-white border-b border-gray-200 px-6 py-4 flex justify-between items-center">
        <h3 className="text-xl font-semibold text-gray-800">Filtri di Ricerca</h3>
        <button
          onClick={onClose}
          className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
          aria-label="Chiudi filtri"
        >
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className="h-6 w-6 text-gray-500" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M6 18L18 6M6 6l12 12" 
            />
          </svg>
        </button>
      </div>

      {/* Filters Content */}
      <div className="p-6 space-y-6">
        {/* ID Riparazione */}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            ID Riparazione
          </label>
          <input
            type="text"
            name="id_repair"
            value={filters.id_repair}
            onChange={handleInputChange}
            placeholder="Inserisci ID"
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
          />
        </div>

        {/* Codice Esterno */}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Codice Esterno
          </label>
          <input
            type="text"
            name="externalCode"
            value={filters.externalCode}
            onChange={handleInputChange}
            placeholder="Inserisci codice"
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
          />
        </div>

        {/* Cliente */}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Cliente
          </label>
          <select
            name="id_customer"
            value={filters.id_customer}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
          >
            <option value="">Seleziona Cliente</option>
            {customers.map((customer) => (
              <option key={customer.id_customer} value={customer.id_customer}>
                {customer.companyName}
              </option>
            ))}
          </select>
        </div>

        {/* Laboratorio */}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Laboratorio
          </label>
          <select
            name="id_laboratory"
            value={filters.id_laboratory}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
          >
            <option value="">Seleziona Laboratorio</option>
            {laboratories.map((laboratory) => (
              <option key={laboratory.id_laboratory} value={laboratory.id_laboratory}>
                {laboratory.name}
              </option>
            ))}
          </select>
        </div>

        {/* Descrizione */}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Descrizione
          </label>
          <input
            type="text"
            name="description"
            value={filters.description}
            onChange={handleInputChange}
            placeholder="Inserisci descrizione"
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
          />
        </div>

        {/* Data Ingresso Range */}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Data Ingresso
          </label>
          <div className="grid grid-cols-2 gap-4">
            <input
              type="date"
              name="entry_date_start"
              value={filters.entry_date_start}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
            />
            <input
              type="date"
              name="entry_date_end"
              value={filters.entry_date_end}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
            />
          </div>
        </div>

        {/* Data Consegna Range */}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Data Consegna Prevista
          </label>
          <div className="grid grid-cols-2 gap-4">
            <input
              type="date"
              name="delivery_date_start"
              value={filters.delivery_date_start}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
            />
            <input
              type="date"
              name="delivery_date_end"
              value={filters.delivery_date_end}
              onChange={handleInputChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
            />
          </div>
        </div>

        {/* Stato Corrente */}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Stato Corrente
          </label>
          <select
            name="current_status"
            value={filters.current_status}
            onChange={handleInputChange}
            className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-150"
          >
            <option value="">Seleziona Stato</option>
            <option value="Accettazione">Accettazione</option>
            <option value="In Attesa">In Attesa</option>
            <option value="In Lavorazione">In Lavorazione</option>
            <option value="Laboratorio Esterno">Laboratorio Esterno</option>
            <option value="Completata">Completata</option>
            <option value="Consegnata">Consegnata</option>
          </select>
        </div>
      </div>

      {/* Footer Actions */}
      <div className="absolute inset-x-0 bottom-0 bg-white border-t border-gray-200 p-6 space-y-3">
        <button
          onClick={handleSearchClick}
          className="w-full bg-blue-600 text-white px-4 py-3 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-150"
        >
          Applica Filtri
        </button>
        <button
          onClick={handleReset}
          className="w-full bg-gray-100 text-gray-700 px-4 py-3 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 transition duration-150"
        >
          Reimposta Filtri
        </button>
      </div>
    </div>
  );
};

export default SidebarRepair;