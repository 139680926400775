// InternalDischargePage.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { generateInternalDischargePDF } from '../utils/InternalDischaregePdf';
import InternalDischargeCustomerData from '../components/InternalDischargeCustomerData';
import InternalDischargeDocumentData from '../components/InternalDischargeDocumentData';
import InternalDischargeInput from '../components/InternalDischargeInput';

const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';

const InternalDischargePage = () => {
  const location = useLocation();
  const [customerData, setCustomerData] = useState({});
  const [documentDetails, setDocumentDetails] = useState({});
  const [inputData, setInputData] = useState([]);
  const [message, setMessage] = useState('');
  const [isSaved, setIsSaved] = useState(false); // Stato per tracciare se il documento è stato salvato
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.selectedRepairs) {
      setInputData(location.state.selectedRepairs);
    }
  }, [location.state]);

  const handleSaveInternalDischarge = async () => {
    try {
      if (!documentDetails.document_number) {
        setMessage('Numero del documento obbligatorio.');
        return;
      }
      if (!documentDetails.creation_date) {
        setMessage('Data di creazione obbligatoria.');
        return;
      }
      if (!customerData.id_customer) {
        setMessage('Selezionare un cliente.');
        return;
      }
  
      // Salva i dati dello scarico interno nel database
      const dischargeData = {
        document_number: documentDetails.document_number,
        creation_date: documentDetails.creation_date,
        notes: documentDetails.notes || '',
        id_customer: customerData.id_customer,
        repairs: inputData.filter((item) => item.repairCode).map((item) => ({
          repair_code: item.repairCode,
          description: item.description,
          price: item.price || 0,
          quantity: item.quantity,
        })),
      };
  
      console.log('Dati da inviare al server Internal Discharge:', dischargeData);
  
      const response = await axios.post(`${serverUrl}/internal-discharges`, dischargeData);
      console.log('risposta dopo invio dei dati internal storage',response);
  
      // Genera il PDF dello scarico interno
      const pdfDoc = generateInternalDischargePDF(documentDetails, customerData, inputData);
  
      // Salva il PDF sul server
      const pdfBlob = pdfDoc.output('blob');
      const formData = new FormData();
      formData.append('pdf', pdfBlob, `${documentDetails.document_number}.pdf`);
      formData.append('document_number', documentDetails.document_number); // Aggiunta del numero documento al formData
      const fileUploadResponse = await axios.post(`${serverUrl}/internal-discharges/upload-file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (fileUploadResponse.status === 200) {
        console.log('File PDF caricato con successo:', fileUploadResponse.data);
        setMessage('Scarico interno salvato con successo e PDF generato!');
        setIsSaved(true); // Imposta lo stato come salvato

        // Aggiorna il percorso del PDF nello scarico interno
        await axios.put(`${serverUrl}/internal-discharges/${documentDetails.document_number}`, {
          pdf_path: fileUploadResponse.data.pdfPath,
        });
      } else {
        throw new Error('Errore durante l upload del file PDF.');
      }
    } catch (error) {
      console.error('Errore durante il salvataggio dello scarico interno:', error);
      setMessage('Errore durante il salvataggio dello scarico interno.');
    }
  };
  

  const handlePrintInternalDischarge = async () => {
    try {
      if (!documentDetails.document_number) {
        setMessage('Numero del documento obbligatorio per la stampa.');
        return;
      }

      const response = await axios.get(`${serverUrl}/internal-discharges/pdf/${documentDetails.document_number}/`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      window.open(url, '_blank');
    } catch (error) {
      console.error('Errore durante il recupero del PDF dello scarico interno:', error);
      setMessage('Errore durante il recupero del PDF.');
    }
  };

// Handle exit from the page
const handleExit = () => {
  if (location.state?.from) {
    navigate(location.state.from); // Torna alla pagina di provenienza
  } else {
    navigate('/Home'); // Vai alla home se non c'è stato di provenienza
  }
};

  return (
    <div className="p-5 max-w-6xl mx-auto bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-5">Scarico Interno</h1>

      <div className="lg:flex lg:space-x-5 space-y-5 lg:space-y-0 mb-5">
        <div className="flex-1 bg-blue-50 p-5 rounded-lg shadow-md">
          <InternalDischargeCustomerData customerData={customerData} setCustomerData={setCustomerData} />
        </div>
        <div className="flex-1 bg-blue-50 p-5 rounded-lg shadow-md">
          <InternalDischargeDocumentData documentDetails={documentDetails} setDocumentDetails={setDocumentDetails} />
        </div>
      </div>
      <div className="mb-5">
        <InternalDischargeInput documentLines={inputData} setDocumentLines={setInputData} />
      </div>

      <div className="flex justify-end gap-3 mt-5">
        <button onClick={handleSaveInternalDischarge} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300">
          Salva Scarico Interno
        </button>
        <button
          onClick={handlePrintInternalDischarge}
          disabled={!isSaved} // Disabilita il pulsante se il documento non è stato salvato
          className={`px-4 py-2 rounded transition duration-300 ${
            isSaved
              ? 'bg-green-500 text-white hover:bg-green-700'
              : 'bg-gray-400 text-gray-800 cursor-not-allowed'
          }`}
        >
          Stampa PDF
        </button>
        <button onClick={handleExit} className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-800 transition duration-300">
          Esci
        </button>
      </div>

      {message && <div className={`mt-5 p-3 rounded text-lg ${message.startsWith('Errore') ? 'bg-red-200 text-red-800 border border-red-400' : 'bg-green-200 text-green-800 border border-green-400'}`}>{message}</div>}
    </div>
  );
};

export default InternalDischargePage;
