import ShopService from './ShopService';

export const generateInvoiceXML = async (invoiceData, customerData) => {
  try {
    console.log('Starting Invoice XML generation with:', { invoiceData, customerData });

    // Get shop data
    console.log('Fetching shop data...');
    const shopData = await ShopService.getDefaultShop();
    console.log('Raw shop data received:', shopData);

    // Ensure we have valid shop data
    if (!shopData) {
      console.error('Shop data is null or undefined');
      throw new Error('Dati del negozio non trovati');
    }

    // Extract and map shop data to the correct fields
    const shopInfo = {
      name: shopData.name || '',
      address: shopData.address || '',
      city: shopData.city || '',
      province: shopData.province || '',
      cap: shopData.cap || '',
      vatInfo: shopData.vatInfo || '',
      country: 'IT'
    };

    // Log mapped data
    console.log('Mapped shop data:', shopInfo);

    // Verify each field individually
    if (!shopInfo.name) throw new Error('Nome negozio mancante');
    if (!shopInfo.address) throw new Error('Indirizzo negozio mancante');
    if (!shopInfo.city) throw new Error('Città negozio mancante');
    if (!shopInfo.province) throw new Error('Provincia negozio mancante');
    if (!shopInfo.vatInfo) throw new Error('P.IVA/Codice Fiscale negozio mancante');

    console.log('All shop data validated successfully');

    // Verifica che le note siano disponibili
    if (!invoiceData.notes) {
      console.error("Nessuna nota presente per estrarre i dati DDT.");
      return;
    }

    // Funzione per estrarre i dati DDT dalle note
    const extractDDTData = (notes) => {
      console.log("Note Fattura:", notes);
      const ddtPattern = /DDT (\d+) del ([\d/]+)/g;
      let match;
      const ddtReferences = [];
    
      while ((match = ddtPattern.exec(notes)) !== null) {
        const [day, month, year] = match[2].split('/');
        const formattedDate = `${year}-${month}-${day}`;
    
        ddtReferences.push({
          numeroDDT: match[1],
          dataDDT: formattedDate,
          riferimentoNumeroLinea: []
        });
      }
    
      return ddtReferences;
    };
    
    // Estrazione dei dati DDT dalle note
    const ddtReferences = extractDDTData(invoiceData.notes);

    if (ddtReferences.length === 0) {
      console.error("Nessun DDT trovato nelle note.");
      return;
    }

    // Formattazione della data
    const formatDate = (dateString) => {
      if (!dateString) return new Date().toISOString().split('T')[0];
      const date = new Date(dateString);
      return date.toISOString().split('T')[0];
    };

    // Generazione dell'XML
    const xmlData = `<?xml version="1.0" encoding="UTF-8"?>
<ns0:FatturaElettronica xmlns:ns0="http://ivaservizi.agenziaentrate.gov.it/docs/xsd/fatture/v1.2" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" versione="FPR12" xsi:schemaLocation="http://ivaservizi.agenziaentrate.gov.it/docs/xsd/fatture/v1.2 https://www.fatturapa.gov.it/export/documenti/fatturapa/v1.2.1/Schema_del_file_xml_FatturaPA_versione_1.2.1a.xsd">
  <FatturaElettronicaHeader>
    <DatiTrasmissione>
      <IdTrasmittente>
        <IdPaese>IT</IdPaese>
        <IdCodice>${shopInfo.vatInfo}</IdCodice>
      </IdTrasmittente>
      <ProgressivoInvio>00001</ProgressivoInvio>
      <FormatoTrasmissione>FPR12</FormatoTrasmissione>
      <CodiceDestinatario>${customerData.codiceSdi || '0000000'}</CodiceDestinatario>
    </DatiTrasmissione>
    <CedentePrestatore>
      <DatiAnagrafici>
        <IdFiscaleIVA>
          <IdPaese>IT</IdPaese>
          <IdCodice>${shopInfo.vatInfo}</IdCodice>
        </IdFiscaleIVA>
        <CodiceFiscale>${shopInfo.vatInfo}</CodiceFiscale>
        <Anagrafica>
          <Denominazione>${shopInfo.name}</Denominazione>
        </Anagrafica>
        <RegimeFiscale>RF01</RegimeFiscale>
      </DatiAnagrafici>
      <Sede>
        <Indirizzo>${shopInfo.address}</Indirizzo>
        <CAP>${shopInfo.cap}</CAP>
        <Comune>${shopInfo.city}</Comune>
        <Provincia>${shopInfo.province}</Provincia>
        <Nazione>IT</Nazione>
      </Sede>
    </CedentePrestatore>
    <CessionarioCommittente>
      <DatiAnagrafici>
        <IdFiscaleIVA>
          <IdPaese>IT</IdPaese>
          <IdCodice>${customerData.vatNumber || ''}</IdCodice>
        </IdFiscaleIVA>
        <Anagrafica>
          <Denominazione>${customerData.companyName || ''}</Denominazione>
        </Anagrafica>
      </DatiAnagrafici>
      <Sede>
        <Indirizzo>${customerData.address || ''}</Indirizzo>
        <CAP>${customerData.cap || ''}</CAP>
        <Comune>${customerData.citta || ''}</Comune>
        <Provincia>${customerData.provincia || ''}</Provincia>
        <Nazione>${customerData.nazione || 'IT'}</Nazione>
      </Sede>
    </CessionarioCommittente>
  </FatturaElettronicaHeader>
  <FatturaElettronicaBody>
    <DatiGenerali>
      <DatiGeneraliDocumento>
        <TipoDocumento>TD01</TipoDocumento>
        <Divisa>EUR</Divisa>
        <Data>${formatDate(invoiceData.creation_date)}</Data>
        <Numero>${invoiceData.document_number}</Numero>
        <ImportoTotaleDocumento>${parseFloat(invoiceData.total_price || 0).toFixed(2)}</ImportoTotaleDocumento>
        ${ddtReferences.map(ddt => `
        <DatiDDT>
          <NumeroDDT>${ddt.numeroDDT}</NumeroDDT>
          <DataDDT>${ddt.dataDDT}</DataDDT>
        </DatiDDT>`).join('')}
      </DatiGeneraliDocumento>
    </DatiGenerali>
    <DatiBeniServizi>
      ${(invoiceData.lines || []).map((line, index) => `
      <DettaglioLinee>
        <NumeroLinea>${index + 1}</NumeroLinea>
        <Descrizione>${line.description || ''}</Descrizione>
        <Quantita>${line.quantity || '1'}</Quantita>
        <PrezzoUnitario>${parseFloat(line.price || 0).toFixed(2)}</PrezzoUnitario>
        <PrezzoTotale>${parseFloat((line.price * (line.quantity || 1)) || 0).toFixed(2)}</PrezzoTotale>
        <AliquotaIVA>22.00</AliquotaIVA>
      </DettaglioLinee>`).join('')}
      <DatiRiepilogo>
        <AliquotaIVA>22.00</AliquotaIVA>
        <ImponibileImporto>${parseFloat(invoiceData.price || 0).toFixed(2)}</ImponibileImporto>
        <Imposta>${parseFloat(invoiceData.vat || 0).toFixed(2)}</Imposta>
      </DatiRiepilogo>
    </DatiBeniServizi>
    <DatiPagamento>
      <CondizioniPagamento>TP02</CondizioniPagamento>
      <DettaglioPagamento>
        <ModalitaPagamento>MP05</ModalitaPagamento>
        <DataScadenzaPagamento>${formatDate(invoiceData.creation_date)}</DataScadenzaPagamento>
        <ImportoPagamento>${parseFloat(invoiceData.total_price || 0).toFixed(2)}</ImportoPagamento>
      </DettaglioPagamento>
    </DatiPagamento>
  </FatturaElettronicaBody>
</ns0:FatturaElettronica>`;

    return new Blob([xmlData], { type: 'application/xml' });
  } catch (error) {
    console.error('Error generating invoice XML:', error);
    throw error;
  }
};
