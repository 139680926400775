import React, { useState, useRef, useEffect } from 'react';
import Webcam from 'react-webcam';
import ShopService from '../utils/ShopService';


const RepairEditModal = ({
  repair,
  isOpen,
  onClose,
  onSave,
  users,
  customers,
  statuses,
  laboratories,
}) => {
  const webcamRef = useRef(null);
  const [image, setImage] = useState(null);
  const [imageFromPC, setImageFromPC] = useState(null);
  const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';
  const existingPhotoUrl = repair?.pathPhoto ? `${serverUrl}${repair.pathPhoto}` : null;
  const [isMobile, setIsMobile] = useState(false);
  const [useBackCamera, setUseBackCamera] = useState(true);
  const [errorMessages, setErrorMessages] = useState({});
  const [shopId, setShopId] = useState(null);
  const [shopIdError, setShopIdError] = useState(false);
  const [printError, setPrintError] = useState(null);
  const [isPrinting, setIsPrinting] = useState(false);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');

  // Preparazione dei dati iniziali della riparazione
  const prepareInitialRepairData = (repair, shopId) => ({
    id_user: repair?.id_user || '',
    id_customer: repair?.id_customer || '',
    externalCode: repair?.externalCode || '',
    description: repair?.description || '',
    work_to_be_done: repair?.work_to_be_done || '',
    id_laboratory: repair?.id_laboratory || '',
    current_status: repair?.current_status || '',
    entry_date: repair?.entry_date || '',
    expected_delivery_date: repair?.expected_delivery_date || '',
    estimated_cost: repair?.estimated_cost || '',
    public_price: repair?.public_price || '',
    estimate: repair?.estimate || '',
    parts_price: repair?.parts_price || '',
    id_shop: shopId || repair?.id_shop || null,
  });

  const [localRepair, setLocalRepair] = useState(prepareInitialRepairData(repair, null));

  // Recupero dell'ID del negozio all'apertura del modale
  useEffect(() => {
    if (isOpen) {
      const fetchShopId = async () => {
        try {
          console.log('Tentativo di recuperare dati del negozio...');
          const shopData = await ShopService.getDefaultShop();
          console.log('Dati del negozio ricevuti:', shopData);
          
          if (shopData && shopData.id) {  
            console.log('ID del negozio trovato:', shopData.id);
            setShopId(shopData.id);  
            setShopIdError(false);
            
            // Aggiorna localRepair con l'ID del negozio
            setLocalRepair(prev => ({
              ...prev,
              id_shop: shopData.id  
            }));
          } else {
            console.warn('Nessun ID del negozio trovato nei dati ricevuti');
            setShopIdError(true);
          }
        } catch (error) {
          console.error('Errore nel recuperare l\'ID del negozio:', error);
          setShopIdError(true);
        }
      };
      fetchShopId();
    }
  }, [isOpen]);

  // Log per debug
  useEffect(() => {
    console.log('localRepair aggiornato:', localRepair);
    console.log('shopId corrente:', shopId);
  }, [localRepair, shopId]);

  // Aggiornamento dei dati quando repair cambia
  useEffect(() => {
    if (repair) {
      setLocalRepair(prepareInitialRepairData(repair, shopId));
    }
  }, [repair, shopId]);

  // Rilevamento dispositivo mobile
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsMobile(/Mobi|Android|iPhone/i.test(userAgent));
  }, []);

  if (!isOpen) return null;

  const capturePhoto = () => {
    const screenshot = webcamRef.current.getScreenshot();
    if (screenshot) {
      fetch(screenshot)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], `photo_${repair?.id_repair || 'new'}.jpg`, {
            type: 'image/jpeg',
          });
          setImage(file);
        });
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFromPC(file);
    }
  };

  // Gestione del salvataggio con validazione dell'ID del negozio
  const handleSaveClick = () => {
    // Verifica l'ID del negozio prima di salvare
    if (!shopId && !localRepair.id_shop) {
      setErrorMessages(prev => ({
        ...prev,
        shop: 'Impossibile salvare: ID del negozio non trovato'
      }));
      return;
    }

    const requiredFields = ['id_user', 'entry_date', 'id_laboratory', 'id_customer', 'current_status','description'];
    const newErrorMessages = {};

    requiredFields.forEach((field) => {
      if (!localRepair[field]) {
        newErrorMessages[field] = 'Questo campo è obbligatorio';
      }
    });

    if (Object.keys(newErrorMessages).length > 0) {
      setErrorMessages(newErrorMessages);
      return;
    }

    setErrorMessages({});

    const photoToSend = repair?.id_repair ? null : image || imageFromPC;
    console.log('Salvataggio riparazione con i seguenti dati:', {
      localRepair: {
        ...localRepair,
        shopId: shopId,
        id_shop: localRepair.id_shop
      }
    });
    if (localRepair.id_repair) {
      onSave(null, localRepair);
    } else {
      onSave(photoToSend, {
        ...localRepair,
        id_shop: shopId || localRepair.id_shop
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalRepair((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const toggleCameraFacing = () => {
    setUseBackCamera((prev) => !prev);
  };


  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white w-full max-w-4xl p-6 rounded-lg shadow-xl flex flex-col max-h-[90vh]">
        <h2 className="text-2xl font-bold mb-4 text-center">
          {repair?.id_repair ? 'Modifica Riparazione' : 'Crea Riparazione'}
        </h2>

        {shopIdError && (
          <div className="text-red-500 mb-4">
            Errore: Impossibile recuperare l'ID del negozio. Contattare l'assistenza.
          </div>
        )}

        <div className="overflow-y-auto flex-grow pr-4 mb-4">
          <form className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="repair-edit-form-group">
              <label className="block text-lg font-medium mb-2">Utente</label>
              <select
                name="id_user"
                value={localRepair.id_user}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
              >
                <option value="">Seleziona un utente</option>
                {users.map((user) => (
                  <option key={user.id_user} value={user.id_user}>
                    {user.name}
                  </option>
                ))}
              </select>
              {errorMessages.id_user && (
                <p className="text-red-500 text-sm mt-1">{errorMessages.id_user}</p>
              )}
            </div>

            <div className="repair-edit-form-group">
              <label className="block text-lg font-medium mb-2">Cliente</label>
              <select
                name="id_customer"
                value={localRepair.id_customer}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
              >
                <option value="">Seleziona un cliente</option>
                {customers.map((customer) => (
                  <option key={customer.id_customer} value={customer.id_customer}>
                    {customer.companyName}
                  </option>
                ))}
              </select>
              {errorMessages.id_customer && (
                <p className="text-red-500 text-sm mt-1">{errorMessages.id_customer}</p>
              )}
            </div>

            <div className="repair-edit-form-group">
              <label className="block text-lg font-medium mb-2">Codice Esterno</label>
              <input
                type="text"
                name="externalCode"
                value={localRepair.externalCode}
                onChange={handleChange}
                placeholder="Codice esterno"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
              />
            </div>

            <div className="repair-edit-form-group col-span-full">
              <label className="block text-lg font-medium mb-2">Descrizione</label>
              <textarea
                name="description"
                value={localRepair.description}
                onChange={handleChange}
                placeholder="Descrizione della riparazione"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
              />
              {errorMessages.description && (
                <p className="text-red-500 text-sm mt-1">{errorMessages.description}</p>
              )}
            </div>

            <div className="repair-edit-form-group col-span-full">
              <label className="block text-lg font-medium mb-2">Lavoro da Eseguire</label>
              <textarea
                name="work_to_be_done"
                value={localRepair.work_to_be_done}
                onChange={handleChange}
                placeholder="Dettagli del lavoro da eseguire"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
              />
            </div>

            <div className="repair-edit-form-group">
              <label className="block text-lg font-medium mb-2">Laboratorio</label>
              <select
                name="id_laboratory"
                value={localRepair.id_laboratory}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
              >
                <option value="">Seleziona un laboratorio</option>
                {laboratories.map((laboratory) => (
                  <option key={laboratory.id_laboratory} value={laboratory.id_laboratory}>
                    {laboratory.name}
                  </option>
                ))}
              </select>
              {errorMessages.id_laboratory && (
                <p className="text-red-500 text-sm mt-1">{errorMessages.id_laboratory}</p>
              )}
            </div>

            <div className="repair-edit-form-group">
              <label className="block text-lg font-medium mb-2">Stato</label>
              <select
                name="current_status"
                value={localRepair.current_status}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
              >
                <option value="">Seleziona uno stato</option>
                {statuses.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
              {errorMessages.current_status && (
                <p className="text-red-500 text-sm mt-1">{errorMessages.current_status}</p>
              )}
            </div>

            <div className="repair-edit-form-group">
              <label className="block text-lg font-medium mb-2">Data di Entrata</label>
              <input
                type="date"
                name="entry_date"
                value={localRepair.entry_date}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
              />
              {errorMessages.entry_date && (
                <p className="text-red-500 text-sm mt-1">{errorMessages.entry_date}</p>
              )}
            </div>

            <div className="repair-edit-form-group">
              <label className="block text-lg font-medium mb-2">Data di Consegna Prevista</label>
              <input
                type="date"
                name="expected_delivery_date"
                value={localRepair.expected_delivery_date}
                onChange={handleChange}
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
              />
            </div>

            <div className="repair-edit-form-group">
              <label className="block text-lg font-medium mb-2">Costo Stimato</label>
              <input
                type="number"
                name="estimated_cost"
                value={localRepair.estimated_cost}
                onChange={handleChange}
                placeholder="Costo stimato"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
              />
            </div>

            <div className="repair-edit-form-group">
              <label className="block text-lg font-medium mb-2">Prezzo Pubblico</label>
              <input
                type="number"
                name="public_price"
                value={localRepair.public_price}
                onChange={handleChange}
                placeholder="Prezzo pubblico"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
              />
            </div>

            <div className="repair-edit-form-group">
              <label className="block text-lg font-medium mb-2">Preventivo Fornito</label>
              <input
                type="number"
                name="estimate"
                value={localRepair.estimate}
                onChange={handleChange}
                placeholder="Preventivo fornito"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
              />
            </div>

            <div className="repair-edit-form-group">
              <label className="block text-lg font-medium mb-2">Costo Parti</label>
              <input
                type="number"
                name="parts_price"
                value={localRepair.parts_price}
                onChange={handleChange}
                placeholder="Costo delle parti"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-300"
              />
            </div>

            {existingPhotoUrl && (
              <div className="repair-edit-form-group col-span-full">
                <label className="block text-lg font-medium mb-2">Foto Esistente</label>
                <img src={existingPhotoUrl} alt="Foto esistente" className="w-full rounded-lg shadow-md" />
              </div>
            )}

            {!repair?.id_repair && (
              <>
                <div className="repair-edit-form-group col-span-full">
                  <label className="block text-lg font-medium mb-2">Scatta una Foto</label>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    forceScreenshotSourceSize={true}
                    videoConstraints={{
                      facingMode: useBackCamera ? 'environment' : 'user',
                    }}
                    onUserMediaError={(error) => {
                      console.error('Errore durante l’accesso alla webcam:', error);
                      alert(
                        'Non è possibile accedere alla webcam. Assicurati che il browser abbia il permesso di accedere alla fotocamera.'
                      );
                    }}
                    className="w-full rounded-lg shadow-md mb-3"
                  />
                  {isMobile && (
                    <button
                      type="button"
                      onClick={toggleCameraFacing}
                      className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition mb-2"
                    >
                      Cambia Fotocamera ({useBackCamera ? 'Posteriore' : 'Anteriore'})
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={capturePhoto}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
                  >
                    Scatta Foto
                  </button>
                </div>

                <div className="repair-edit-form-group col-span-full">
                  <label className="block text-lg font-medium mb-2">Carica una foto dal PC</label>
                  <input type="file" accept="image/*" onChange={handleImageUpload} className="w-full p-2 border rounded-lg" />
                </div>

                {image && (
                  <div className="col-span-full mb-4">
                    <h4 className="text-lg font-medium mb-2">Anteprima Foto dalla Webcam:</h4>
                    <img src={URL.createObjectURL(image)} alt="Foto catturata" className="w-full rounded-lg shadow-md" />
                  </div>
                )}

                {imageFromPC && (
                  <div className="col-span-full mb-4">
                    <h4 className="text-lg font-medium mb-2">Anteprima Foto da PC:</h4>
                    <img src={URL.createObjectURL(imageFromPC)} alt="Foto caricata" className="w-full rounded-lg shadow-md" />
                  </div>
                )}
              </>
            )}
          </form>
        </div>

        {/* Buttons Save and Cancel */}
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={handleSaveClick}
            disabled={shopIdError}
            className={`bg-green-500 text-white px-6 py-3 rounded-lg shadow-md hover:bg-green-600 transition-all ${
              shopIdError ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            Salva
          </button>

         
        </div>

        {printError && (
          <div className="text-red-500 mt-2">
            Errore: {printError}
          </div>
        )}
        {message && (
          <div className={`text-${messageType} mt-2`}>
            {message}
          </div>
        )}
      </div>
    </div>
  );
};

export default RepairEditModal;
