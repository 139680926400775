import React, { useState, useEffect } from 'react';
import ShopService from '../utils/ShopService';

const SetupPage = () => {
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [shopData, setShopData] = useState(null);
  const [showNetPrice, setShowNetPrice] = useState(false);
  const [printers, setPrinters] = useState([]);
  const [selectedPrinter, setSelectedPrinter] = useState('');

  // Load shop data and printer settings on component mount
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        // Carica i dati del negozio
        const data = await ShopService.getShopById(1);
        setShopData(data);
        
        // Carica preferenza showNetPrice
        const savedPreference = localStorage.getItem('showNetPrice');
        setShowNetPrice(savedPreference === 'true');
        
        // Carica stampante salvata
        const savedPrinter = localStorage.getItem('selectedPrinter');
        if (savedPrinter) {
          setSelectedPrinter(savedPrinter);
        }

        // Fetch printers from server
        const fetchPrinters = async () => {
          try {
            const response = await fetch(`${process.env.REACT_APP_API_SERVERPRINT_URL}/api/printers`);
            if (!response.ok) {
              throw new Error('Failed to fetch printers');
            }
            const printerList = await response.json();
            setPrinters(printerList);
          } catch (error) {
            console.error('Errore nel recupero delle stampanti:', error);
            setMessage('Errore nel recupero delle stampanti');
            setMessageType('error');
          }
        };

        fetchPrinters();
      } catch (error) {
        console.error('Error loading initial data:', error);
        setMessage('Errore nel caricamento dei dati iniziali');
        setMessageType('error');
      }
    };
    loadInitialData();
  }, []);

  const handlePrinterChange = (event) => {
    const printer = event.target.value;
    setSelectedPrinter(printer);
    localStorage.setItem('selectedPrinter', printer);
    setMessage('Stampante salvata con successo!');
    setMessageType('success');
  };

  const handleRefreshCache = async () => {
    try {
      await ShopService.refreshCache();
      setMessage('Cache aggiornata con successo!');
      setMessageType('success');
    } catch (error) {
      console.error('Error refreshing cache:', error);
      setMessage('Errore durante l\'aggiornamento della cache: ' + error.message);
      setMessageType('error');
    }
  };

  const handlePriceDisplayChange = (event) => {
    const newValue = event.target.checked;
    setShowNetPrice(newValue);
    localStorage.setItem('showNetPrice', newValue);
    setMessage('Preferenza salvata con successo!');
    setMessageType('success');
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Impostazioni</h1>
      
      {message && (
        <div className={`p-4 mb-4 rounded ${
          messageType === 'error' ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'
        }`}>
          {message}
        </div>
      )}

      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-4">Stampante Etichette</h2>
          <select
            value={selectedPrinter}
            onChange={handlePrinterChange}
            className="w-full p-2 border rounded"
          >
            <option value="">Seleziona una stampante</option>
            {printers.map((printer, index) => (
              <option key={index} value={printer.deviceId}>
                {printer.label || printer.deviceId}
              </option>
            ))}
          </select>
          <p className="text-sm text-gray-600 mt-2">
            Seleziona la stampante da utilizzare per le etichette
          </p>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-4">Visualizzazione Prezzi</h2>
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={showNetPrice}
              onChange={handlePriceDisplayChange}
              className="mr-2"
            />
            <span>Mostra prezzi netti (senza IVA)</span>
          </label>
        </div>

        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-4">Gestione Cache</h2>
          <button
            onClick={handleRefreshCache}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Aggiorna Cache
          </button>
          <p className="text-sm text-gray-600 mt-2">
            Aggiorna i dati in cache del negozio
          </p>
        </div>
      </div>
    </div>
  );
};

export default SetupPage;