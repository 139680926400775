import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';

import RepairEditModal from '../components/RepairEditModal'; 
import MassiveImportModal from '../components/MassiveImportModal '; 
import SidebarRepair from '../components/SidebarRepair'; 
import RepairTable from '../components/RepairTable'; 


const RiparazioniPage = () => {
  const [repairs, setRepairs] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [selectedRepairs, setSelectedRepairs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMassiveImportOpen, setIsMassiveImportOpen] = useState(false);
  const [currentRepair, setCurrentRepair] = useState(null);
  const [users, setUsers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [laboratories, setLaboratories] = useState([]);
  const [statuses] = useState([
    'Accettazione', 'In Attesa', 'In Lavorazione', 'Laboratorio Esterno', 'Completata', 'Consegnata'
  ]);
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('');
  const [selectedImage, setSelectedImage] = useState(null); 
  const [imageToShow, setImageToShow] = useState(null);
  
  const location = useLocation();

  const navigate = useNavigate();

   // Function to open and close sidebar
   const toggleSidebar = () => {
    setIsSidebarOpen((prevIsOpen) => !prevIsOpen);
  };



  // Load data functions
  const searchRepairs = async (filters) => {
    try {
      const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';
      const response = await axios.get(`${serverUrl}/repairs/search`, { params: filters });
      setRepairs(response.data);
      console.log('ricerca da inviare a repairtable:', response);
    } catch (error) {
      console.error('Error searching repairs:', error);
    }
  };
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';
        const [repairsResponse, usersResponse, customersResponse, laboratoriesResponse] = await Promise.all([
          axios.get(`${serverUrl}/repairs`),
          axios.get(`${serverUrl}/users`),
          axios.get(`${serverUrl}/customers`),
          axios.get(`${serverUrl}/laboratories`),
        ]);

        // Introduci un ritardo per mostrare il caricamento più a lungo
        setTimeout(() => {
          setRepairs(repairsResponse.data);
          setUsers(usersResponse.data);
          setCustomers(customersResponse.data);
          setLaboratories(laboratoriesResponse.data);
          setLoading(false); // Disattiva il caricamento
        }, 1000); // Ritardo di 1 secondi
      } catch (error) {
        console.error('Errore durante il caricamento dei dati:', error);
        setLoading(false); // Disattiva il caricamento anche in caso di errore
      }
    };
    fetchData();
  }, []);

  const displayMessage = (text, type = 'success') => {
    setMessage(text);
    setMessageType(type);
    setTimeout(() => {
      setMessage('');
    }, 3000);
  };

  // Function to select/deselect repairs
  const toggleSelectRepair = (repairId) => {
    setSelectedRepairs((prevSelected) =>
      prevSelected.includes(repairId)
        ? prevSelected.filter(id => id !== repairId)
        : [...prevSelected, repairId]
    );
  };

  // Function to delete a repair
  const handleDelete = async (repairId) => {
    try {
      const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';
      await axios.delete(`${serverUrl}/repairs/${repairId}`);
      setRepairs(repairs.filter(repair => repair.id_repair !== repairId));
      displayMessage('Riparazione eliminata con successo.');
    } catch (error) {
      console.error(`Error deleting repair with ID ${repairId}`, error);
      displayMessage('Errore durante leliminazione della riparazione.', 'error');
    }
  };

 
  const handleExit = () => {
    if (location.state?.from) {
      navigate(location.state.from); // Torna alla pagina di provenienza
    } else {
      navigate('/Home'); // Vai alla home se non c'è stato di provenienza
    }
  };

  // Function to open the modal for editing or adding a repair
  const openModal = (repair = null) => {
    const newRepair = repair
      ? { ...repair }
      : { id_user: '', id_customer: '', entry_date: dayjs().format('YYYY-MM-DD'), expected_delivery_date: '', externalCode: '' };
    setCurrentRepair(newRepair);
    setSelectedImage(null);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentRepair(null);
    setSelectedImage(null);
  };

  // Function to save a repair
  const handleSave = async (webcamImage, localRepair) => {
    try {
      console.log('Dati ricevuti per salvataggio riparazione:', {
        webcamImage: webcamImage ? 'Immagine presente' : 'Nessuna immagine',
        localRepair: {
          ...localRepair,
          // Nascondi eventuali dati sensibili
          password: localRepair.password ? '***' : null
        }
      });

      const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';
      const { id_repair, ...repairData } = localRepair;

      const formattedRepairData = {
        ...repairData,  
        entry_date: dayjs(localRepair.entry_date).isValid() ? dayjs(localRepair.entry_date).format('YYYY-MM-DD') : null,
        expected_delivery_date: dayjs(localRepair.expected_delivery_date).isValid() ? dayjs(localRepair.expected_delivery_date).format('YYYY-MM-DD') : null,
        estimated_cost: localRepair.estimated_cost || null,
        public_price: localRepair.public_price || null,
        estimate: localRepair.estimate || null,
        parts_price: localRepair.parts_price || null,
        id_shop: localRepair.id_shop || null,
      };

      console.log('Dati formattati per salvataggio:', {
        id_repair,
        formattedRepairData
      });

      let repairResponse;

      if (id_repair) {
        repairResponse = await axios.put(`${serverUrl}/repairs/${id_repair}`, formattedRepairData);
        displayMessage('Riparazione modificata con successo.');
      } else {
        repairResponse = await axios.post(`${serverUrl}/repairs`, formattedRepairData);
        displayMessage('Riparazione aggiunta con successo.');
      }

      console.log('Risposta del server dopo salvataggio:', repairResponse.data);

      const repairId = repairResponse.data.repair.id_repair;

      if (webcamImage || selectedImage) {
        const formData = new FormData();
        const imageToSend = webcamImage || selectedImage;
        formData.append('id_repair', repairId);
        formData.append('imageData', imageToSend);

        await axios.post(`${serverUrl}/repairs/upload-photo`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });

        displayMessage('Riparazione e foto salvate con successo.');
      }

      if (localRepair.current_status) {
        await axios.put(`${serverUrl}/repairStatuses/${repairId}`, {
          current_status: localRepair.current_status,
        });
        displayMessage('Stato della riparazione aggiornato con successo.');
      }

      const repairsResponse = await axios.get(`${serverUrl}/repairs`);
      setRepairs(repairsResponse.data);

      closeModal();
    } catch (error) {
      console.error('Errore durante il salvataggio della riparazione:', error);
      console.error('Dettagli dell\'errore:', JSON.stringify(error.response?.data || error, null, 2));
      displayMessage('Errore durante il salvataggio della riparazione.', 'error');
    }
  };

  // Function to handle image change
  const handleImageChange = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  // Function to display the image in full size
  const showImageInLarge = (imagePath) => {
    setImageToShow(imagePath);
  };

  const closeImageView = () => {
    setImageToShow(null);
  };

 /*  // Function to open/close sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  }; */

  // Function to handle search
  const handleSearch = (filters) => {
    searchRepairs(filters);
  };

  // Function to open the Massive Import modal
  const openMassiveImportModal = () => {
    setIsMassiveImportOpen(true);
  };

  // Function to close the Massive Import modal
  const closeMassiveImportModal = () => {
    setIsMassiveImportOpen(false);
  };

  // Function to handle the import of repairs from the Massive Import modal
  const handleMassiveImport = async () => {
    try {
      const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';
      const repairsResponse = await axios.get(`${serverUrl}/repairs`);
      setRepairs(repairsResponse.data);
      displayMessage('Riparazioni importate con successo.');
    } catch (error) {
      console.error('Errore durante importazione massiva:', error);
      displayMessage('Errore durante importazione massiva.', 'error');
    }
  };

  return (
    <div className="repair-page-container max-w-max mx-auto p-6 flex flex-col min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-center">Riparazioni</h1>
      <button 
        className="filter-button bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition" 
        onClick={toggleSidebar}
      >
        Filtri
      </button>
  
      {message && (
        <div className={`message-bar ${messageType} mt-4 p-4 rounded text-center ${
          messageType === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
        }`}>
          {message}
        </div>
      )}
  
      <div className="content-container my-6 flex-grow">
        <RepairTable
          repairs={repairs}
          loading={loading}
          toggleSelectRepair={toggleSelectRepair}
          selectedRepairs={selectedRepairs}
          showImageInLarge={showImageInLarge}
          openModal={openModal}
          handleDelete={handleDelete}
        />
      </div>
  
      <div className="footer-container flex justify-end gap-4 mt-8 fixed bottom-0 left-0 right-0 bg-white p-4 shadow-lg">
        <button className="footer-button bg-blue-500 text-white px-5 py-3 rounded-lg shadow-md hover:bg-blue-600 transition-all" onClick={() => openModal()}>
          Inserisci Riparazione
        </button>
        <button className="footer-button bg-green-500 text-white px-5 py-3 rounded-lg shadow-md hover:bg-green-600 transition-all" onClick={openMassiveImportModal}>
          Importo Massivo
        </button>
       
        <button className="footer-button bg-gray-500 text-white px-5 py-3 rounded-lg shadow-md hover:bg-gray-600 transition-all" onClick={handleExit}>
          Esci
        </button>
      </div>
  
      {isModalOpen && (
        <RepairEditModal
          repair={currentRepair}
          isOpen={isModalOpen}
          onClose={closeModal}
          onSave={handleSave}
          onImageChange={handleImageChange}
          users={users}
          customers={customers}
          laboratories={laboratories}
          statuses={statuses}
        />
      )}
  
      {isMassiveImportOpen && (
        <MassiveImportModal
          show={isMassiveImportOpen}
          onClose={closeMassiveImportModal}
          customers={customers}
          onImport={handleMassiveImport}
        />
      )}
  
  {isSidebarOpen && (
        <>
          {/* Overlay */}
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            onClick={toggleSidebar}
          />
          {/* Sidebar */}
          <div className={`fixed inset-y-0 right-0 w-80 bg-white shadow-xl z-50 transform transition-transform duration-300 ease-in-out ${
            isSidebarOpen ? 'translate-x-0' : 'translate-x-full'
          }`}>
            <SidebarRepair 
              onClose={toggleSidebar} 
              onSearch={handleSearch} 
              customers={customers} 
              laboratories={laboratories}
              isOpen={isSidebarOpen} 
            />
          </div>
        </>
      )}
  
      {imageToShow && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50" onClick={closeImageView}>
          <div className="image-modal-content">
            <img src={imageToShow} alt="Riparazione" className="large-image max-w-full max-h-screen border-4 border-white" />
          </div>
        </div>
      )}
    </div>
  );
  
};

export default RiparazioniPage;
