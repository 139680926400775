import React, { useState } from 'react';

const CustomerModal = ({ customer, isOpen, onClose, onSave, onChange }) => {
  const [phoneError, setPhoneError] = useState('');
  const [capError, setCapError] = useState('');

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl h-[80vh] flex flex-col">
        {/* Header */}
        <div className="p-4 bg-gray-100 border-b">
          <h2 className="text-lg font-semibold">
            {customer?.id_customer ? 'Modifica Cliente' : 'Crea Cliente'}
          </h2>
        </div>

        {/* Contenuto Scorrevole */}
        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {/* Nome */}
            <div>
              <label className="block text-sm font-medium">Nome</label>
              <input
                type="text"
                name="companyName"
                value={customer?.companyName || ''}
                onChange={onChange}
                className="w-full border border-gray-300 rounded-lg p-2"
                placeholder="Nome"
              />
            </div>

            {/* Telefono */}
            <div>
              <label className="block text-sm font-medium">Telefono</label>
              <input
                type="text"
                name="phone"
                value={customer?.phone || ''}
                onChange={(e) => {
                  const phonePattern = /^[0-9]*$/;
                  if (!phonePattern.test(e.target.value)) {
                    setPhoneError('Inserire solo numeri');
                  } else {
                    setPhoneError('');
                  }
                  onChange(e);
                }}
                className={`w-full border ${phoneError ? 'border-red-500' : 'border-gray-300'} rounded-lg p-2`}
                placeholder="Telefono"
              />
              {phoneError && <p className="text-red-500 text-xs mt-1">{phoneError}</p>}
            </div>

            {/* Email */}
            <div>
              <label className="block text-sm font-medium">Email</label>
              <input
                type="email"
                name="email"
                value={customer?.email || ''}
                onChange={onChange}
                className="w-full border border-gray-300 rounded-lg p-2"
                placeholder="Email"
              />
            </div>

            {/* PEC */}
            <div>
              <label className="block text-sm font-medium">PEC</label>
              <input
                type="email"
                name="pec"
                value={customer?.pec || ''}
                onChange={onChange}
                className="w-full border border-gray-300 rounded-lg p-2"
                placeholder="PEC"
              />
            </div>

            {/* Indirizzo */}
            <div>
              <label className="block text-sm font-medium">Indirizzo</label>
              <input
                type="text"
                name="address"
                value={customer?.address || ''}
                onChange={onChange}
                className="w-full border border-gray-300 rounded-lg p-2"
                placeholder="Indirizzo"
              />
            </div>

            {/* CAP */}
            <div>
              <label className="block text-sm font-medium">CAP</label>
              <input
                type="text"
                name="cap"
                value={customer?.cap || ''}
                onChange={(e) => {
                  const capPattern = /^[0-9]{5}$/;
                  if (!capPattern.test(e.target.value)) {
                    setCapError('CAP non valido (5 numeri)');
                  } else {
                    setCapError('');
                  }
                  onChange(e);
                }}
                className={`w-full border ${capError ? 'border-red-500' : 'border-gray-300'} rounded-lg p-2`}
                placeholder="CAP"
              />
              {capError && <p className="text-red-500 text-xs mt-1">{capError}</p>}
            </div>

            {/* Città */}
            <div>
              <label className="block text-sm font-medium">Città</label>
              <input
                type="text"
                name="citta"
                value={customer?.citta || ''}
                onChange={onChange}
                className="w-full border border-gray-300 rounded-lg p-2"
                placeholder="Città"
              />
            </div>

            {/* Provincia */}
            <div>
              <label className="block text-sm font-medium">Provincia</label>
              <input
                type="text"
                name="provincia"
                value={customer?.provincia || ''}
                onChange={onChange}
                className="w-full border border-gray-300 rounded-lg p-2"
                placeholder="Provincia"
              />
            </div>

            {/* Partita IVA */}
            <div>
              <label className="block text-sm font-medium">Partita IVA</label>
              <input
                type="text"
                name="vatNumber"
                value={customer?.vatNumber || ''}
                onChange={onChange}
                className="w-full border border-gray-300 rounded-lg p-2"
                placeholder="Partita IVA"
              />
            </div>

            {/* Codice Fiscale */}
            <div>
              <label className="block text-sm font-medium">Codice Fiscale</label>
              <input
                type="text"
                name="taxCode"
                value={customer?.taxCode || ''}
                onChange={onChange}
                className="w-full border border-gray-300 rounded-lg p-2"
                placeholder="Codice Fiscale"
              />
            </div>

            {/* Codice SDI */}
            <div>
              <label className="block text-sm font-medium">Codice SDI</label>
              <input
                type="text"
                name="codiceSdi"
                value={customer?.codiceSdi || ''}
                onChange={onChange}
                className="w-full border border-gray-300 rounded-lg p-2"
                placeholder="Codice SDI"
              />
            </div>
          </div>
        </div>

        {/* Barra inferiore fissa */}
        <div className="p-4 bg-blue-50 border-t flex justify-end space-x-4">
          <button
            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
            onClick={onClose}
          >
            Annulla
          </button>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={onSave}
          >
            Salva
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomerModal;
