import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ShowStates = ({ show, handleClose, repairId }) => {
  const [states, setStates] = useState([]);

  // Recupera gli stati della riparazione dal server
  useEffect(() => {
    if (repairId) {
      const fetchStates = async () => {
        try {
          const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';
          const response = await axios.get(`${serverUrl}/repairStatuses/${repairId}`);
          setStates(response.data);
        } catch (error) {
          console.error('Errore durante il recupero degli stati della riparazione:', error);
        }
      };

      fetchStates();
    }
  }, [repairId]);

  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Stati della Riparazione</h2>
        <ul>
          {states.map((state, index) => (
            <li key={index}>
              <strong>Stato:</strong> {state.status} <br />
              <strong>Data Cambio Stato:</strong> {new Date(state.status_change_date).toLocaleDateString()}
            </li>
          ))}
        </ul>
        <button onClick={handleClose}>Chiudi</button>
      </div>
    </div>
  );
};

export default ShowStates;
