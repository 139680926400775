import React, { useState } from 'react';

/**
 * Modal component for closing receipt and handling payment
 */

const REACT_APP_API_SERVERPRINT_URL = process.env.REACT_APP_API_SERVERPRINT_URL;

const CloseScontrinoModal = ({ isOpen, onClose, cartItems, total, shopId }) => {
    const [paymentType, setPaymentType] = useState('CASH');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [lotteryCode, setLotteryCode] = useState('');
    const [cashAmount, setCashAmount] = useState('');

    // Calculate change if paying with cash
    const calculateChange = () => {
        if (!cashAmount || paymentType !== 'CASH') return 0;
        const change = parseFloat(cashAmount) - total;
        return change > 0 ? change : 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
    
        try {
            // Prepara i dati dello scontrino
            const showNetPrice = localStorage.getItem('showNetPrice') === 'true';
            
            const receiptData = {
                shopId,
                items: cartItems.map(item => ({
                    description: item.description || 'Riparazione',
                    price: parseFloat(item.public_price), // Il prezzo è già corretto da CassaPage
                    department: '2'
                })),
                total: parseFloat(total), // Il totale è già corretto da CassaPage
                paymentType: paymentType === 'CASH' ? '1' : paymentType === 'CARD' ? '3' : '2',
                lotteryCode: lotteryCode || undefined
            };
    
            console.log('Dati inviati al server di stampa:', receiptData);
    
            // Invia al server di stampa
            const response = await fetch(`${REACT_APP_API_SERVERPRINT_URL}/fiscal-printer/print`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(receiptData),
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Errore HTTP: ${response.status} - ${errorText}`);
            }

            const result = await response.json();
            console.log('Risposta dal server:', result);
            
            if (!result.success) {
                throw new Error(result.error || 'Errore di stampa');
            }

            onClose();
        } catch (err) {
            console.error('Errore stampa:', err);
            setError('Errore durante la stampa: ' + err.message);
        } finally {
            setLoading(false);
        }
    };
    
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white rounded-lg p-6 w-full max-w-md">
                <form onSubmit={handleSubmit}>
                    <h2 className="text-2xl font-bold mb-4">Chiusura Scontrino</h2>
                    
                    {error && (
                        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
                            {error}
                        </div>
                    )}

                    {/* Payment Type Selection */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Metodo di Pagamento
                        </label>
                        <select 
                            value={paymentType}
                            onChange={(e) => setPaymentType(e.target.value)}
                            disabled={loading}
                            className="shadow border rounded w-full py-2 px-3 text-gray-700"
                        >
                            <option value="CASH">Contanti</option>
                            <option value="CARD">Carta di Credito</option>
                            <option value="CHECK">Assegno</option>
                        </select>
                    </div>

                    {/* Cash Amount Input (only for cash payments) */}
                    {paymentType === 'CASH' && (
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                Contante Ricevuto
                            </label>
                            <input
                                type="number"
                                step="0.01"
                                value={cashAmount}
                                onChange={(e) => setCashAmount(e.target.value)}
                                className="shadow border rounded w-full py-2 px-3 text-gray-700"
                                placeholder="0.00"
                            />
                            {calculateChange() > 0 && (
                                <p className="text-green-600 mt-1">
                                    Resto: €{calculateChange().toFixed(2)}
                                </p>
                            )}
                        </div>
                    )}.js

                    {/* Lottery Code Input */}
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">
                            Codice Lotteria (opzionale)
                        </label>
                        <input
                            type="text"
                            value={lotteryCode}
                            onChange={(e) => setLotteryCode(e.target.value)}
                            className="shadow border rounded w-full py-2 px-3 text-gray-700"
                            placeholder="Inserisci codice lotteria"
                        />
                    </div>

                    {/* Total Display */}
                    <div className="mb-6 text-right">
                        <span className="text-xl font-bold">
                            Totale: €{total.toFixed(2)}
                        </span>
                    </div>

                    {/* Action Buttons */}
                    <div className="flex justify-end gap-4">
                        <button
                            type="button"
                            onClick={onClose}
                            disabled={loading}
                            className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                        >
                            Annulla
                        </button>
                        <button
                            type="submit"
                            disabled={loading || (paymentType === 'CASH' && parseFloat(cashAmount) < total)}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:opacity-50"
                        >
                            {loading ? 'Stampa in corso...' : 'Stampa Scontrino'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CloseScontrinoModal;