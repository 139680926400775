// InternalDischargeInput.js
import React, { useState, useEffect } from 'react';
import ShopService from '../utils/ShopService';

const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';

const InternalDischargeInput = ({ documentLines, setDocumentLines }) => {
  const [inputData, setInputData] = useState({
    quantity: 1,
    repairCode: '',
    description: '',
    price: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [imponibile, setImponibile] = useState(0);
  const [aliquota, setAliquota] = useState(0.22); // Default VAT rate

  useEffect(() => {
    // Fetch shop data and set VAT rate
    const fetchShopData = async () => {
      try {
        const shopData = await ShopService.getDefaultShop();
        if (shopData && shopData.vatRate) {
          setAliquota(parseFloat(shopData.vatRate) / 100);
        }
      } catch (error) {
        console.error('Error fetching shop data:', error);
      }
    };
    fetchShopData();
  }, []);

  useEffect(() => {
    // Calculate the imponibile whenever documentLines changes
    const total = documentLines.reduce((acc, line) => acc + parseFloat(line.price || 0) * parseInt(line.quantity || 1), 0);
    setImponibile(total);
  }, [documentLines]);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputData((prevInput) => ({
      ...prevInput,
      [name]: value,
    }));
  };

  // Handle keypress for repair code input
  const handleRepairCodeKeyPress = async (e) => {
    if (e.key === 'Enter') {
      try {
        const response = await fetch(`${serverUrl}/repairs/${inputData.repairCode}`);
        if (response.ok) {
          const repair = await response.json();

          // Check if the repair is already "Consegnata"
          if (repair.current_status === 'Consegnata') {
            setErrorMessage(`La riparazione con codice ${repair.id_repair} è già stata consegnata e non può essere aggiunta allo scarico interno.`);
            return;
          }

          // If the repair is not "Consegnata", proceed to add it to the input data
          setInputData((prevInput) => ({
            ...prevInput,
            description: `cod Rip ${repair.id_repair}  ${repair.description}`,
            price: repair.public_price || 0,
          }));

          setErrorMessage(''); // Clear any previous error messages
        } else {
          setErrorMessage('Riparazione non trovata.');
        }
      } catch (error) {
        console.error('Error fetching repair data:', error);
        setErrorMessage('Errore durante la ricerca della riparazione.');
      }
    }
  };

  // Handle adding a line item
  const handleAddLine = () => {
    setDocumentLines((prevLines) => [...prevLines, inputData]);
    // Reset input fields after adding
    setInputData({ quantity: 1, repairCode: '', description: '', price: '' });
  };

  // Handle removing a line item
  const handleRemoveLine = (index) => {
    setDocumentLines((prevLines) => prevLines.filter((_, i) => i !== index));
  };

  const iva = imponibile * aliquota;
  const totale = imponibile + iva;

  return (
    <div className="p-5 bg-white rounded-lg shadow-lg">
      <div className="flex flex-wrap gap-4 mb-5">
        <input
          type="number"
          name="quantity"
          value={inputData.quantity}
          onChange={handleInputChange}
          className="p-3 border rounded-lg w-20 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="text"
          name="repairCode"
          value={inputData.repairCode}
          onChange={handleInputChange}
          onKeyPress={handleRepairCodeKeyPress}
          className="p-3 border rounded-lg flex-grow focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Codice Riparazione"
        />
        <input
          type="text"
          name="description"
          value={inputData.description}
          onChange={handleInputChange}
          className="p-3 border rounded-lg flex-grow focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Descrizione"
        />
        <input
          type="text"
          name="price"
          value={inputData.price}
          onChange={handleInputChange}
          className="p-3 border rounded-lg w-32 focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Prezzo"
        />
        <button onClick={handleAddLine} className="px-6 py-3 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-700 transition duration-300">
          Aggiungi
        </button>
      </div>

      {/* Error message display */}
      {errorMessage && (
        <div className="mb-5 text-red-500 font-semibold">{errorMessage}</div>
      )}

      {/* Table to display added lines */}
      <table className="w-full bg-gray-100 rounded-lg shadow-md">
        <thead className="bg-blue-500 text-white">
          <tr>
            <th className="p-3">Quantità</th>
            <th className="p-3">Descrizione</th>
            <th className="p-3">Prezzo</th>
            <th className="p-3">Azione</th>
          </tr>
        </thead>
        <tbody>
          {documentLines.map((line, index) => (
            <tr key={index} className="text-center border-b">
              <td className="p-3">{line.quantity}</td>
              <td className="p-3">{line.description}</td>
              <td className="p-3">{line.price ||0}</td>
              <td className="p-3">
                <button
                  onClick={() => handleRemoveLine(index)}
                  className="text-red-500 hover:text-red-700"
                >
                  <span role="img" aria-label="Elimina">🗑️</span>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Totals section */}
      <div className="mt-5 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
          <label className="font-semibold">Aliquota IVA:</label>
          <input type="text" value={`${aliquota * 100}%`} readOnly className="text-right bg-transparent w-20 focus:outline-none" />
        </div>
        <div className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
          <label className="font-semibold">Imponibile:</label>
          <input type="text" value={`€ ${imponibile.toFixed(2)}`} readOnly className="text-right bg-transparent w-20 focus:outline-none" />
        </div>
        <div className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
          <label className="font-semibold">IVA:</label>
          <input type="text" value={`€ ${iva.toFixed(2)}`} readOnly className="text-right bg-transparent w-20 focus:outline-none" />
        </div>
        <div className="flex items-center justify-between p-3 bg-blue-50 rounded-lg">
          <label className="font-semibold">Totale:</label>
          <input type="text" value={`€ ${totale.toFixed(2)}`} readOnly className="text-right bg-transparent w-20 focus:outline-none" />
        </div>
      </div>
    </div>
  );
};

export default InternalDischargeInput;