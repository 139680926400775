import React, { useEffect, useState } from 'react';
import axios from 'axios';

const serverUrl = process.env.REACT_APP_SERVER_URL || 'http://localhost:4000';

const DDTDocumentData = ({ documentDetails = {}, setDocumentDetails, errors = {} }) => {
  const [laboratories, setLaboratories] = useState([]);

  // useEffect per ottenere i laboratori e il prossimo numero di documento
  useEffect(() => {
    const fetchNextDocumentNumberAndLaboratories = async () => {
      try {
        // Recupero del prossimo numero di documento
        const response = await axios.get(`${serverUrl}/ddts/next-document-number`);
        if (response.status === 200 && response.data.nextDocumentNumber) {
          setDocumentDetails((prevDetails) => ({
            ...prevDetails,
            document_number: response.data.nextDocumentNumber,
            creation_date: prevDetails.creation_date || new Date().toISOString().split('T')[0], // Imposta la data corrente se non è già stata impostata
          }));
        }

        // Recupero dei laboratori
        const laboratoriesResponse = await axios.get(`${serverUrl}/laboratories`);
        setLaboratories(laboratoriesResponse.data);
      } catch (error) {
        console.error('Errore durante il recupero dei dati:', error);
      }
    };

    fetchNextDocumentNumberAndLaboratories();
  }, [setDocumentDetails]);

  // Funzione per aggiornare i dettagli del documento
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDocumentDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-4 text-gray-800 text-center">Dati Documento</h2>
      <form className="grid gap-6 grid-cols-1 lg:grid-cols-2">
        {/* Colonna sinistra */}
        <div className="space-y-1">
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Numero Documento</label>
            <input
              type="number"
              name="document_number"
              value={documentDetails.document_number || ''}
              onChange={handleChange}
              className={`w-full p-2 border ${errors.document_number ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
            {errors.document_number && <p className="text-red-500 text-sm">{errors.document_number}</p>}
          </div>
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Causale Trasporto</label>
            <select
              name="transport_reason"
              value={documentDetails.transport_reason || ''}
              onChange={handleChange}
              className={`w-full p-2 border ${errors.transport_reason ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            >
              <option value="">Seleziona...</option>
              <option value="Reso">Reso</option>
              <option value="Riparazione">Riparazione</option>
              <option value="C/Lavorazione">C/Lavorazione</option>
              <option value="Reso da c/lavorazione">Reso da c/lavorazione</option>
            </select>
            {errors.transport_reason && <p className="text-red-500 text-sm">{errors.transport_reason}</p>}
          </div>
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Peso (kg)</label>
            <input
              type="number"
              name="weight"
              value={documentDetails.weight || ''}
              onChange={handleChange}
              className={`w-full p-2 border ${errors.weight ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
            {errors.weight && <p className="text-red-500 text-sm">{errors.weight}</p>}
          </div>
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Trasporto a cura del</label>
            <select
              name="transport_by"
              value={documentDetails.transport_by || ''}
              onChange={handleChange}
              className={`w-full p-2 border ${errors.transport_by ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            >
              <option value="">Seleziona...</option>
              <option value="Mittente">Mittente</option>
              <option value="Destinatario">Destinatario</option>
              <option value="Vettore">Vettore</option>
            </select>
            {errors.transport_by && <p className="text-red-500 text-sm">{errors.transport_by}</p>}
          </div>

             {/* Campo Stato Riparazioni */}
             <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Stato Riparazioni</label>
            <select
              name="repair_status"
              value={documentDetails.repair_status || ''}
              onChange={handleChange}
              className={`w-full p-2 border ${errors.repair_status ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
              required
            >
              <option value="">Seleziona uno stato...</option>
              <option value="Accettazione">Accettazione</option>
              <option value="In Attesa">In Attesa</option>
              <option value="In Lavorazione">In Lavorazione</option>
              <option value="Laboratorio esterno">Laboratorio esterno</option>
              <option value="Completata">Completata</option>
              <option value="Consegnata">Consegnata</option>
            </select>
            {errors.repair_status && <p className="text-red-500 text-sm">{errors.repair_status}</p>}
          </div>
          {/* Campo di selezione del laboratorio */}
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Laboratorio Esterno</label>
            <select
              name="id_laboratory"
              value={documentDetails.id_laboratory || ''}
              onChange={handleChange}
              className={`w-full p-2 border ${errors.id_laboratory ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            >
              <option value="">Seleziona un laboratorio...</option>
              {laboratories.map((laboratory) => (
                <option key={laboratory.id_laboratory} value={laboratory.id_laboratory}>
                  {laboratory.name}
                </option>
              ))}
            </select>
            {errors.id_laboratory && <p className="text-red-500 text-sm">{errors.id_laboratory}</p>}
          </div>

        </div>

        {/* Colonna destra */}
        <div className="space-y-1">
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Data</label>
            <input
              type="date"
              name="creation_date"
              value={documentDetails.creation_date || ''}
              onChange={handleChange}
              className={`w-full p-2 border ${errors.creation_date ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
            {errors.creation_date && <p className="text-red-500 text-sm">{errors.creation_date}</p>}
          </div>
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Numero Colli</label>
            <input
              type="number"
              name="number_of_packages"
              value={documentDetails.number_of_packages || ''}
              onChange={handleChange}
              className={`w-full p-2 border ${errors.number_of_packages ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            />
            {errors.number_of_packages && <p className="text-red-500 text-sm">{errors.number_of_packages}</p>}
          </div>
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Porto</label>
            <select
              name="shipment_port"
              value={documentDetails.shipment_port || ''}
              onChange={handleChange}
              className={`w-full p-2 border ${errors.shipment_port ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            >
              <option value="">Seleziona...</option>
              <option value="Assegnato">Assegnato</option>
              <option value="Franco">Franco</option>
            </select>
            {errors.shipment_port && <p className="text-red-500 text-sm">{errors.shipment_port}</p>}
          </div>
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Corriere</label>
            <select
              name="courier"
              value={documentDetails.courier || ''}
              onChange={handleChange}
              className={`w-full p-2 border ${errors.courier ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            >
              <option value="">Seleziona...</option>
              <option value="Nessuno">Nessuno</option>
              <option value="DHL">DHL</option>
              <option value="BRT">BRT</option>
              <option value="GLS">GLS</option>
              <option value="Poste Italiane">Poste Italiane</option>
              <option value="Fedex">Fedex</option>
            </select>
            {errors.courier && <p className="text-red-500 text-sm">{errors.courier}</p>}
          </div>
          <div className="form-group">
            <label className="block text-sm font-medium mb-1 text-gray-700">Aspetto dei beni esteriori</label>
            <select
              name="appearance_of_goods"
              value={documentDetails.appearance_of_goods || ''}
              onChange={handleChange}
              className={`w-full p-2 border ${errors.appearance_of_goods ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500`}
            >
              <option value="">Seleziona...</option>
              <option value="A vista">A vista</option>
              <option value="Busta">Busta</option>
              <option value="Borsa">Borsa</option>
              <option value="Scatola">Scatola</option>
            </select>
            {errors.appearance_of_goods && <p className="text-red-500 text-sm">{errors.appearance_of_goods}</p>}
          </div>
        </div>
      </form>

      {/* Note */}
      <div className="form-group mt-6">
        <label className="block text-sm font-medium mb-1 text-gray-700">Note</label>
        <textarea
          name="notes"
          value={documentDetails.notes || ''}
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
        />
      </div>
    </div>
  );
};

export default DDTDocumentData;
