// InvoicePDF.js
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import ShopService from './ShopService';

export const generateInvoicePDF = async (invoiceData, customerData) => {
  console.log('Starting Invoice PDF generation with:', { invoiceData, customerData });
  const doc = new jsPDF();

  try {
    // Get shop data
    console.log('Fetching shop data...');
    const shopData = await ShopService.getDefaultShop();
    console.log('Raw shop data received:', shopData);

    // Ensure we have valid shop data
    if (!shopData) {
      console.error('Shop data is null or undefined');
      throw new Error('Dati del negozio non trovati');
    }

    // Extract and map shop data to the correct fields
    const shopInfo = {
      name: shopData.name || '',
      address: shopData.address || '',
      city: shopData.city || '',
      province: shopData.province || '',
      cap: shopData.cap || '',
      vatInfo: shopData.vatInfo || '',
      country: 'IT'
    };
    
    // Log mapped data
    console.log('Mapped shop data:', shopInfo);

    // Verify each field individually
    if (!shopInfo.name) throw new Error('Nome negozio mancante');
    if (!shopInfo.address) throw new Error('Indirizzo negozio mancante');
    if (!shopInfo.city) throw new Error('Città negozio mancante');
    if (!shopInfo.province) throw new Error('Provincia negozio mancante');
    if (!shopInfo.vatInfo) throw new Error('P.IVA/Codice Fiscale negozio mancante');

    console.log('All shop data validated successfully');

    // Header - Azienda Mittente
    doc.setFontSize(14);
    doc.text(shopInfo.name.trim(), 10, 10);
    doc.setFontSize(12);
    doc.text(`${shopInfo.address.trim()}, ${shopInfo.cap.trim()}`, 10, 15);
    doc.text(`${shopInfo.city.trim()} ${shopInfo.province.trim()}`, 10, 20);
    doc.text(shopInfo.country.trim(), 10, 25);
    doc.text(`P.IVA e C.F.: ${shopInfo.vatInfo.trim()}`, 10, 30);

    // Validate customer data
    if (!customerData) {
      console.error('Customer data is missing');
      throw new Error('Dati cliente mancanti');
    }

    // Customer Info
    doc.setFontSize(12);
    doc.text('Intestatario', 10, 50);
    doc.text(customerData.companyName || 'N/D', 10, 55);
    doc.text(customerData.address || 'N/D', 10, 60);
    doc.text([
      customerData.cap || 'N/D',
      customerData.citta || 'N/D',
      customerData.provincia || 'N/D'
    ].filter(Boolean).join(' '), 10, 65);
    doc.text(customerData.nazione || 'IT', 10, 70);
    doc.text(`Codice Fiscale: ${customerData.taxCode || 'N/D'}`, 10, 75);
    doc.text(`Partita IVA: ${customerData.vatNumber || 'N/D'}`, 10, 80);

    // Document Details
    const formatDate = (dateString) => {
      if (!dateString) return 'N/D';
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };

    doc.text('Tipo Documento: Fattura', 140, 50);
    doc.text(`Nr: ${invoiceData.document_number || 'N/D'}`, 140, 55);
    doc.text(`Data: ${formatDate(invoiceData.creation_date)}`, 140, 60);
    doc.text(`Tipo di Pagamento: ${invoiceData.payment_type || 'N/D'}`, 10, 90);

    // Table - Document Lines
    const tableColumnHeaders = ['Quantità', 'Descrizione', 'Prezzo'];
    const tableRows = (invoiceData.lines || []).map((line) => [
      line.quantity || '1',
      line.description || '',
      line.price ? `€ ${parseFloat(line.price).toFixed(2)}` : 'N/D',
    ]);

    // Auto-table for document lines
    doc.autoTable({
      startY: 110,
      head: [tableColumnHeaders],
      body: tableRows,
      theme: 'grid',
      headStyles: { fillColor: [0, 123, 255] },
      margin: { top: 10 },
    });

    // Totals Section
    const finalY = doc.autoTable.previous.finalY + 20;
    doc.text('Totale imponibile:', 120, finalY);
    doc.text(`€ ${parseFloat(invoiceData.price || 0).toFixed(2)}`, 180, finalY, { align: 'right' });
    doc.text('IVA:', 140, finalY + 10);
    doc.text(`€ ${parseFloat(invoiceData.vat || 0).toFixed(2)}`, 180, finalY + 10, { align: 'right' });
    doc.text('Totale:', 140, finalY + 20);
    doc.text(`€ ${parseFloat(invoiceData.total_price || 0).toFixed(2)}`, 180, finalY + 20, { align: 'right' });

    // Notes Section (for DDT references)
    if (invoiceData.notes) {
      doc.text('Note:', 10, finalY + 40);
      doc.text(invoiceData.notes, 10, finalY + 50);
    }

    return doc.output('blob');
  } catch (error) {
    console.error('Error generating invoice PDF:', error);
    throw error;
  }
};
