import React from 'react';
import './AddDocumentModal.css'; // Importiamo il CSS dedicato per il modale

const AddDocumentModal = ({ onClose, onSubmit }) => {
  const handleAddDocument = (documentType) => {
    onSubmit(documentType);
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Aggiungi Documento</h2>
        <div className="document-buttons-container">
          <button className="document-button" onClick={() => handleAddDocument('DDT')}>
            DDT
          </button>
          <button className="document-button" onClick={() => handleAddDocument('Invoice')}>
            Fattura
          </button>
          <button className="document-button" onClick={() => handleAddDocument('InternalDischarge')}>
            Scarico Interno
          </button>
        </div>
        <div className="modal-buttons">
          <button onClick={onClose}>Chiudi</button>
        </div>
      </div>
    </div>
  );
};

export default AddDocumentModal;
